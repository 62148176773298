
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAllinfo } from '../services/AllInfo';  // Asumimos que tienes esta función para obtener los permisos

const PermisosContext = createContext();

export const usePermisos = () => {
    return useContext(PermisosContext);
};

export const PermisosProvider = ({ children, authenticated }) => {
    const [permisos, setPermisos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [verificado, setVerificado] = useState(false);



    // Obtener permisos cuando el usuario se autentique
    useEffect(() => {
        const _username = localStorage.getItem('UserName');
        if (_username) {
            fetchPermisos(_username);
        }
    }, [authenticated]);

    // Función para obtener los permisos
    const fetchPermisos = async (username) => {
        setIsLoading(true);

        try {
            const response = await getAllinfo({ desc_sistema: 'usher', username });

            if (response.ok) {
                const permisosData = response.data.lista_roles_cus.reduce((acc, role) => {
                    role.casos_de_uso.forEach(caso => {
                        acc.push(caso);  // Armamos un array con los casos de uso
                    });
                    return acc;
                }, []);

                setPermisos(permisosData);
                localStorage.setItem('permisos', JSON.stringify(permisosData));
                setVerificado(true);

                localStorage.setItem("fotoUsuario", response.data.datos_usuario.foto_perfil);

            }

        } catch (error) {
            console.error('Error fetching permisos:', error);
        } finally {
            //localStorage.setItem('permisos', JSON.stringify(permisos));
            setIsLoading(false);
        }
    };

    return (
        <PermisosContext.Provider value={{ permisos, isLoading, fetchPermisos }}>
            {children}
        </PermisosContext.Provider>
    );
};
