import {
	Autocomplete,
	Button,
	FormHelperText,
	Grid,
	LinearProgress,
	Paper,
	TextField,
	Tooltip,
	ThemeProvider,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useEffect, useState } from "react";
import { getSistemas } from  "../../../services/SistemaService";
import SistemaRolCUDialog from "./SistemaRolCUDialog";
import Message from  "../../../utils/Message";
import { Box } from "@mui/system";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { getSistemasRolesCUs } from  "../../../services/SistemaRolCuService";
 
import React from 'react';
 

const initialSearchData = {
	id_SistemaRol: ""
};


const nameItemsText = "Asignación de CU a los Roles de Sistemas";
//
// ##################################################################################################################### 
//
const SistemaRolCUTable = (data) => {
	const [searchData, setSearchData] = useState(initialSearchData);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [sistemas, setSistemas] = useState([]);
	const [valueAutoCompleteSistemas, setValueAutoCompleteSistemas] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteSistemas, setInputValueAutoCompleteSistemas] =
		useState("");
	
	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "descripcion_sistema",
			label: "Sistema",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "descripcion_sistema_rol",
			label: "Rol",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "sistema_cu_asignados",
			label: "CUs asignados",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "sistema_cu_asignados", //hacerlo dinámico a este contenido con un msj cuando la CU-asignables viene en false
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					 itemsCrud[dataIndex].CU_asignables ? (
						<>
						<Tooltip title="Editar">
							<IconButton
								size="small"
								aria-label="update"
								onClick={() =>
									handleClickActionCrud(itemsCrud[dataIndex], "update")
								}
							>
								<EditOutlinedIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Ver">
							<IconButton
								size="small"
								aria-label="read"
								onClick={() =>
									handleClickActionCrud(itemsCrud[dataIndex], "read")
								}
							>
								<ZoomInIcon />
							</IconButton>
						</Tooltip>
						{/* <Tooltip title="Generar PDF">
							<IconButton
								size="small"
								aria-label="generarpdf"
								onClick={() =>
									handleGenerarPDF(itemsCrud[dataIndex])
								}
							>
								<PictureAsPdfIcon />
							</IconButton>
						</Tooltip> */}
						</>
					)  : (
						<FormHelperText>No existen Casos de Uso creados en este Sistema</FormHelperText>

					)
					
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 5,
		textLabels: {
			body: {
				noMatch: "No se han encontrado resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################
	//
	useEffect(() => {
		loadSistemas();
	}, [currentPage, rowsPerPage]);

	// ################################################################################
	//
	const handleLimpiar = () => {

		setValueAutoCompleteSistemas({
			id: "",
			label: "",
		});
		setInputValueAutoCompleteSistemas("");
		setSearchData(initialSearchData);
		setItemsCrud([]);
	};
	//
	// ################################################################################
	//
	const handleBuscar = () => {
		setCurrentPage(0);
		load();
		loadSistemas()
	};
	//
	// ################################################################################
	//
	const loadSistemas = async () => {
		let _params = {
			idSistema: valueAutoCompleteSistemas?.id ? valueAutoCompleteSistemas.id : null,
			rows: rowsPerPage,
			first: currentPage * rowsPerPage,
		};

		setIsDoingSomething(true);
		const response = await getSistemasRolesCUs(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			setTotalRowsCount(response.data.count);
			setItemsCrud(response.data.data);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//

	useEffect(() => {	
		load()
	}, []);
	
	const load = async () => {
		setIsDoingSomething(true);
		const responseSistemas = await getSistemas();
		setIsDoingSomething(false);

		if (responseSistemas.ok) {
			let _responseSistemas = responseSistemas.data.data;
			let _sistemas = [];

			_responseSistemas.forEach((item) => {
				_sistemas.push({
					id: item.id,
					label: item.descripcion,
				});
			});
			setSistemas(_sistemas);
		} else {
			setSistemas([]);
			setMessage({
				messageText: responseSistemas.messageText,
				severity: responseSistemas.messageSeverity,
			});
			setShowMessage(true);
		}
		
	};
	//
	// ################################################################################
	//
	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				let params = {
					idSistema: itemCrud.id_sistema,
					descripcionSistema: itemCrud.descripcion_sistema,
					idSistemaRol: itemCrud.id_sistema_rol,
					descripcionRol: itemCrud.descripcion_sistema_rol,
					sistemaHabilitado: itemCrud.habilitado_sistema,
					rolHabilitado: itemCrud.habilitado_sistema_rol			
				}
				
				const response = await getSistemasRolesCUs(params);
				setIsDoingSomething(false);
				if (response.ok) {
					let _response = response.data.data[0]
					_itemCrud = {
						idSistema: _response.id_sistema,
						descripcion_sistema: _response.descripcion_sistema,
						idSistemaRol: _response.id_sistema_rol,
						descripcion_rol: _response.descripcion_sistema_rol,
						sistemaCUList: [..._response.sistema_cu],
						fechaActualizacion: response.data.data.fecha_actualizacion,
						nombreUsuario: response.data.data.nombre_usuario,
						apellidoUsuario: response.data.data.apellido_usuario,
						username: response.data.data.username,
					};
					setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};
	//
	// ################################################################################
	//
	const handleDialogSave = () => {
		load();
		loadSistemas();
		setOpenDialog(false);
	};
	//
	// ################################################################################
	//
	const handleDialogClose = () => {
		setOpenDialog(false);
	};
	//
	// ################################################################################
	//


	// const handleGenerarPDF = (itemCrud) => {
	// 	generarPDF(itemCrud);
	// };

	// //
	// // ################################################################################
	// //
	
	// const generarPDF = async (itemCrud) => {
	// 	let _params = {
	// 		idSistema: itemCrud.id_sistema,
	// 		idSistemaRol: itemCrud.id_sistema_rol
	// 	};

	// 	setIsDoingSomething(true);
	// 	const response = await generarPDFSistemasRolesCUs(_params);

	// 	setIsDoingSomething(false);
	// 	if (!response.ok) {
	// 		setMessage({
	// 			messageText: response.messageText,
	// 			severity: response.messageSeverity,
	// 		});
	// 		setShowMessage(true);
	// 	}
  // 	};

	//
	// ################################################################################
	//

	// let table = data.data.table
	return (
		<>
			<Paper
				
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="sistema"
									size="small"
									value={valueAutoCompleteSistemas}
									onChange={(event, newValue) => {
										setValueAutoCompleteSistemas(newValue);
										setSearchData({
											...searchData,
											idSistema: newValue === null ? "" : newValue.id,
										});
									}}
									inputValue={inputValueAutoCompleteSistemas}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteSistemas(newInputValue);
									}}
									options={sistemas}
									renderInput={(params) => (
										<TextField {...params} label="Sistema" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
						</Grid>
					</Box>

					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={handleBuscar}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={handleLimpiar}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					 
						<MUIDataTable
							columns={tableColumns}
							data={itemsCrud}
							options={tableOptions}
						/>
					 
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<SistemaRolCUDialog
					sistemas={sistemas}
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default SistemaRolCUTable;
