import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { MatxTheme } from "./components";
import SettingsProvider from "./core/contexts/SettingsContext";
import { Box } from "@mui/material";
import routes from "./routes";
import { useState, useEffect } from 'react';
import keycloakObject from "./core/utils/keycloak";
import { getUsuarios } from '../src/services/UsuarioService';
import { PermisosProvider } from './contexts/PermisosContext';  // Importar el contexto

export default function App() {
	const content = useRoutes(routes);
	const [initialized, setInitialized] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);

	// Ya no es necesario obtener permisos aquí directamente 
	// Los permisos estarán disponibles a través del contexto     

	useEffect(() => {
		const initKeycloak = async () => {
			const auth = await keycloakObject.initKeycloak();
			setAuthenticated(auth);
			setInitialized(true);
		};
		initKeycloak();
	}, []);

	useEffect(() => {
		const saveTokenToLocalStorage = () => {
			const token = keycloakObject.getToken();
			if (token) {
				localStorage.setItem('Token', token);

			}
		};

		if (authenticated) {
			saveTokenToLocalStorage();
		}
	}, [authenticated]);

	useEffect(() => {
		const fetchUsuario = async () => {
			let _username = localStorage.getItem("UserName");

			if (!_username) {
				localStorage.setItem("authorization", false);
				return;
			}

			try {
				const response = await getUsuarios({ username: _username });

				console.log('response:', response);

				if (response.ok) {
					const userData = response.data.data[0];
					localStorage.setItem("UserId", userData.id);
					localStorage.setItem("emailUsuario", userData.email);
					localStorage.setItem("nombreUsuario", userData.nombre);
					localStorage.setItem("apellidoUsuario", userData.apellido);
					// localStorage.setItem("fotoUsuario", userData.foto_perfil);
					localStorage.setItem("authorization", true);
					localStorage.setItem("Token", keycloakObject.getToken());
				} else {
					localStorage.setItem("authorization", false);
				}
			} catch (error) {
				console.error('Error al obtener datos del usuario:', error);
				localStorage.setItem("authorization", false);
			}
		};

		if (authenticated) {
			fetchUsuario();
		}
	}, [authenticated]);

	return (
		<SettingsProvider>
			<PermisosProvider authenticated={authenticated}> {/* Asegúrate que esté envolviendo la aplicación */}
				<MatxTheme>
					<CssBaseline />
					{content} {/* Aquí tu contenido de rutas */}
				</MatxTheme>
			</PermisosProvider>
		</SettingsProvider>
	);
}
