//
// #####################################################################################################################
//
export const isValidDate = (date) => {
	return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};
//
// #####################################################################################################################
//
export const padTo2Digits = (num) => {
	return num.toString().padStart(2, "0");
};
//
// #####################################################################################################################
//
export const formatDate = (date = new Date(), format = "YMD") => {

  format = format.toUpperCase();
	let _dateFormatted = "";
	switch (format) {
		case "YMD":
			_dateFormatted = [
				date.getFullYear(),
				padTo2Digits(date.getUTCMonth() + 1),
				padTo2Digits(date.getUTCDate()),
			].join("/");
			break;

      case "YMD-":
        _dateFormatted = [
          date.getFullYear(),
          padTo2Digits(date.getUTCMonth() + 1),
          padTo2Digits(date.getUTCDate()),
        ].join("-");
        break;
  
      case "DMY":
			_dateFormatted = [
				padTo2Digits(date.getUTCDate()),
				padTo2Digits(date.getUTCMonth() + 1),
				date.getFullYear(),
			].join("/");
			break;

		case "DMY HM":
			_dateFormatted = [
				padTo2Digits(date.getUTCDate()),
				padTo2Digits(date.getUTCMonth() + 1),
				date.getFullYear(),
			].join("/") + " " + date.getUTCHours().toString().padStart(2, "0") + ":" + date.getUTCMinutes().toString().padStart(2, "0");
			break;

		default:
	}
	return _dateFormatted;
};
//
// #####################################################################################################################
//
export const isPositiveInteger = (str) => {
	if (typeof str !== "string") {
		return false;
	}

	const num = Number(str);

	if (Number.isInteger(num) && num > 0) {
		return true;
	}

	return false;
};
//
// #####################################################################################################################
//
export const onlyLettersAndSpaces = (str) => {
	return /^[A-Za-z\s]*$/.test(str);
};
//
// #####################################################################################################################
//
export const getAge = (dateString) => {
	let today = new Date();
	let birthDate = new Date(dateString);
	let age = today.getFullYear() - birthDate.getFullYear();
	let m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};
//
// #####################################################################################################################
//
export const isValidEmail = (email) => {
	if (
		String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			) !== null
	) {
		return true;
	} else {
		return false;
	}
};
//
// #####################################################################################################################
//
export const isEmptyObject = (object) => {
	return Object.keys(object).length === 0;
};
//
// #####################################################################################################################
//
export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
//
// #####################################################################################################################
//
export const roundNumber = (par_number) => {
  if (isNaN(par_number)) {
    // si el valor recibido no es un numero
    return 0;
  }
  return Math.round((par_number + Number.EPSILON) * 100) / 100;
}
//
// #####################################################################################################################
//
export const camelToUnderscore = (par_string) => {
  let result = par_string.replace( /([A-Z])/g, " $1" );
  return result.split(' ').join('_').toLowerCase();
}

export const getUserData = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user;
}