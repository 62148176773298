import { Navigate } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";

// Session pages:
import NotFound from "./views/sessions/NotFound";

// Componentes:
import Dashboard from "./views/inicio/Dashboard";
import BancoTable from "./elements/components/banco/BancoTable";
import DepartamentoTable from "./elements/components/departamento/DepartamentoTable";
import EstadoCivilTable from "./elements/components/estado-civil/EstadoCivilTable";
import FeriadoTable from "./elements/components/feriado/FeriadoTable";
import InhabilidadTable from "./elements/components/inhabilidad/InhabilidadTable";
import LocalidadTable from "./elements/components/localidad/LocalidadTable";
import MonedaTable from "./elements/components/moneda/MonedaTable";
import NacionalidadTable from "./elements/components/nacionalidad/NacionalidadTable";
import PaisTable from "./elements/components/pais/PaisTable";
import ProfesionTable from "./elements/components/profesion/ProfesionTable";
import ProvinciaTable from "./elements/components/provincia/ProvinciaTable";
import TipoDocumentoTable from "./elements/components/tipo-documento/TipoDocumentoTable";
import TipoDomicilioTable from "./elements/components/tipo-domicilio/TipoDomicilioTable";
import TipoTelefonoTable from "./elements/components/tipo-telefono/TipoTelefonoTable";
import TipoSocietarioTable from "./elements/components/tipo-societario/TipoSocietarioTable";
import FuncionTable from "./elements/components/funcion/FuncionTable";
import FueroTable from "./elements/components/fuero/FueroTable";
import AreaTable from "./elements/components/area/AreaTable";
import OrganismoTable from "./elements/components/organismo/OrganismoTable";
import OrganismoAreaTable from "./elements/components/organismo-area/OrganismoAreaTable";
import OrganismoFueroTable from "./elements/components/organismo-fuero/OrganismoFueroTable";
import SistemaTable from "./elements/components/sistema/SistemaTable";
import SistemaCUTable from "./elements/components/sistema-cu/SistemaCUTable";
import SistemaRolTable from "./elements/components/sistema-rol/SistemaRolTable";
import SistemaOrganismoTable from "./elements/components/sistema-organismo/SistemaOrganismoTable";
import SistemaRolCUTable from "./elements/components/sistema-rol-cu/SistemaRolCUTable";
import UsuarioTable from "./elements/components/usuario/UsuarioTable";
import UsuarioOrganismoAreaFuncionTable from "./elements/components/usuario-organismo-area-funcion/UsuarioOrganismoAreaFuncionTable";
import SistemaRolUsuarioTable from "./elements/components/sistema-rol-usuario/SistemaRolUsuarioTable";
import UsuarioLicenciaTable from "./elements/components/usuario-licencia/UsuarioLicenciaTable";
import EtapaProcesalTable from "./elements/components/etapa-procesal/EtapaProcesalTable";
import MateriaTable from "./elements/components/materia/MateriaTable";
import TipoVinculoTable from "./elements/components/tipo-vinculo/TipoVinculoTable";
import MateriaTipoVinculoTable from "./elements/components/materia-tipo-vinculo/MateriaTipoVinculoTable";
import TipoActJuzgadoTable from "./elements/components/tipo-act-juzgado/TipoActJuzgadoTable";
import SubtipoActJuzgadoTable from "./elements/components/subtipo-act-juzgado/SubtipoActJuzgadoTable";
import FueroSubtipoActJuzgadoTable from "./elements/components/fuero-subtipo-act-juzgado/FueroSubtipoActJuzgadoTable";
import TipoActParteTable from "./elements/components/tipo-act-parte/TipoActParteTable";
import FueroTipoActParteTable from "./elements/components/fuero-tipo-act-parte/FueroTipoActParteTable";
import ProcesoTable from "./elements/components/proceso/ProcesoTable";
import ProcesoEtapaProcesalTable from "./elements/components/proceso-etapa-procesal/ProcesoEtapaProcesalTable";
import ProcesoSubtipoActJuzgadoTable from "./elements/components/proceso-subtipo-act-juzgado/ProcesoSubtipoActJuzgadoTable";
import ProcesoEtapaProcesalSubtipoActJuzgadoTable from "./elements/components/proceso-etapa-procesal-subtipo-act-juzgado/ProcesoEtapaProcesalSubtipoActJuzgadoTable";
import TipoElevacionTable from "./elements/components/tipo-elevacion/TipoElevacionTable";
import FueroTipoElevacionTable from "./elements/components/fuero-tipo-elevacion/FueroTipoElevacionTable";
import FueroTipoElevacionEtapaProcesalTable from "./elements/components/fuero-tipo-elevacion-etapa-procesal/FueroTipoElevacionEtapaProcesalTable";
import FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoTable from "./elements/components/fuero-tipo-elevacion-etapa-procesal-subtipo-act-juzgado/FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoTable";
import TipoIncidenteTable from "./elements/components/tipo-incidente/TipoIncidenteTable";
import FueroTipoIncidenteTable from "./elements/components/fuero-tipo-incidente/FueroTipoIncidenteTable";
import FueroTipoIncidenteEtapaProcesalTable from "./elements/components/fuero-tipo-incidente-etapa-procesal/FueroTipoIncidenteEtapaProcesalTable";
import FueroTIpoIncidenteEtapaProcesalSubtipoActJuzgadoTable from "./elements/components/fuero-tipo-incidente-etapa-procesal-subtipo-act-juzgado/FueroTipoIncidenteEtapaProcesalSubtipoActJuzgadoTable";

const routes = [
  {
    element: (
      <MatxLayout />
    ),
    children: [
      { path: "/usher/inicio", element: <Dashboard /> },
      { path: "/usher/funcion-table", element: <FuncionTable /> },
      { path: "/usher/fuero-table", element: <FueroTable /> },
      { path: "/usher/area-table", element: <AreaTable /> },
      { path: "/usher/organismo-table", element: <OrganismoTable /> },
      { path: "/usher/organismo-area-table", element: <OrganismoAreaTable /> },
      { path: "/usher/sistema-table", element: <SistemaTable /> },
      { path: "/usher/sistema-cu-table", element: <SistemaCUTable /> },
      { path: "/usher/sistema-rol-table", element: <SistemaRolTable /> },
      { path: "/usher/sistema-organismo-table", element: <SistemaOrganismoTable /> },
      { path: "/usher/sistema-rol-cu-table", element: <SistemaRolCUTable /> },
      { path: "/usher/usuario-table", element: <UsuarioTable /> },
      { path: "/usher/uoaf-table", element: <UsuarioOrganismoAreaFuncionTable /> },
      { path: "/usher/sistema-rol-usuario-table", element: <SistemaRolUsuarioTable /> },
      { path: "/usher/usuario-licencia-table", element: <UsuarioLicenciaTable /> },
      { path: "/usher/organismo-fuero-table", element: <OrganismoFueroTable /> },
      { path: "/usher/etapa-procesal-table", element: <EtapaProcesalTable /> },
      { path: "/usher/materia-table", element: <MateriaTable /> },
      { path: "/usher/tipo-vinculo-table", element: <TipoVinculoTable /> },
      { path: "/usher/materia-tipo-vinculo-table", element: <MateriaTipoVinculoTable /> },
      { path: "/usher/tipo-act-juzgado-table", element: <TipoActJuzgadoTable /> },
      { path: "/usher/subtipo-act-juzgado-table", element: <SubtipoActJuzgadoTable /> },
      { path: "/usher/fuero-subtipo-act-juzgado-table", element: <FueroSubtipoActJuzgadoTable /> },
      { path: "/usher/tipo-act-parte-table", element: <TipoActParteTable /> },
      { path: "/usher/fuero-tipo-act-parte-table", element: <FueroTipoActParteTable /> },
      { path: "/usher/proceso-table", element: <ProcesoTable /> },
      { path: "/usher/proceso-etapa-procesal-table", element: <ProcesoEtapaProcesalTable /> },
      { path: "/usher/proceso-subtipo-act-juzgado-table", element: <ProcesoSubtipoActJuzgadoTable /> },
      { path: "/usher/proceso-etapa-procesal-subtipo-act-juzgado-table", element: <ProcesoEtapaProcesalSubtipoActJuzgadoTable /> },
      { path: "/usher/tipo-elevacion-table", element: <TipoElevacionTable /> },
      { path: "/usher/fuero-tipo-elevacion-table", element: <FueroTipoElevacionTable /> },
      { path: "/usher/fuero-tipo-elevacion-etapa-procesal-table", element: <FueroTipoElevacionEtapaProcesalTable /> },
      { path: "/usher/fuero-tipo-elevacion-etapa-procesal-subtipo-act-juzgado-table", element: <FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoTable /> },
      { path: "/usher/tipo-incidente-table", element: <TipoIncidenteTable /> },
      { path: "/usher/fuero-tipo-incidente-table", element: <FueroTipoIncidenteTable /> },
      { path: "/usher/fuero-tipo-incidente-etapa-procesal-table", element: <FueroTipoIncidenteEtapaProcesalTable /> },
      { path: "/usher/fuero-tipo-incidente-etapa-procesal-subtipo-act-juzgado-table", element: <FueroTIpoIncidenteEtapaProcesalSubtipoActJuzgadoTable /> },
      { path: "/usher/banco-table", element: <BancoTable /> },
      { path: "/usher/departamento-table", element: <DepartamentoTable /> },
      { path: "/usher/estado-civil-table", element: <EstadoCivilTable /> },
      { path: "/usher/feriado-table", element: <FeriadoTable /> },
      { path: "/usher/inhabilidad-table", element: <InhabilidadTable /> },
      { path: "/usher/localidad-table", element: <LocalidadTable /> },
      { path: "/usher/moneda-table", element: <MonedaTable /> },
      { path: "/usher/nacionalidad-table", element: <NacionalidadTable /> },
      { path: "/usher/pais-table", element: <PaisTable /> },
      { path: "/usher/profesion-table", element: <ProfesionTable /> },
      { path: "/usher/provincia-table", element: <ProvinciaTable /> },
      { path: "/usher/tipo-documento-table", element: <TipoDocumentoTable /> },
      { path: "/usher/tipo-domicilio-table", element: <TipoDomicilioTable /> },
      { path: "/usher/tipo-telefono-table", element: <TipoTelefonoTable /> },
      { path: "/usher/tipo-societario-table", element: <TipoSocietarioTable /> }
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/", element: <Navigate to="usher/inicio" /> },
  { path: "*", element: <Navigate to="usher/inicio" /> }
];

export default routes;
