import { urlBackendUsuarios } from "../core/configs/config";
import "../utils/axios.interceptor";
import HttpService from '../services/HttpService';
HttpService.configure();

const axiosClient = HttpService.getAxiosClient();

const epName = "/sistema-rol-cu/";
const epPDFBuilderName = "/genera_pdf/";
const nameReporte = 'sistemas-roles-cus';
const nameItemsText = "CU asignados a los Roles de un Sistema";
const nameItemText = "Caso de uso";
const itemGender = "M";
const elLa = itemGender === "M" ? "el" : "la";
const unUna = itemGender === "M" ? "un" : "una";

export const getSistemasRolesCUs = async (params) => {
	let _params = {};
	let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;
	if (params) {

		if (params.idSistema) {
			_params.id_sistema = params.idSistema;
		}
		
		if (params.idSistemaRol) {
			_params.id_sistema_rol = params.idSistemaRol;
		}
		
		if (params.rows) {
			_params.rows = params.rows;
		}
		if (params.first) {
			_params.first = params.first;
		}
	}

	try {
		const response = await axiosClient.get(`${urlBackendUsuarios}${epName}`, {
			params: _params,
			headers: {
				"Access-Control-Allow-Origin": "*",
			},
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				return response;
			}
		}

		// si hay algun error controlado

		console.log(_errorMessage);

		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
//
// #############################################################################
//
export const saveAsignation = async (actionCrud, item) => {
	let _errorMessage = `No fue posible guardar ${elLa} ${nameItemText}`;
	let _item = {
		id_sistema: item?.idSistema,
		id_sistema_rol: item?.idSistemaRol,
		sistema_cu_list: item?.sistemaCUList,
		username_actualiza: item?.usernameUsuarioActualiza
	};

	try {
		let response = {};
		if (actionCrud === "update") {
			response = await axiosClient.put(`${urlBackendUsuarios}${epName}`, _item, {
				headers: { "Access-Control-Allow-Origin": "*" },
			});
		}


		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				response.messageText =
					`${nameItemText}` +
					(actionCrud === "disable"
						? itemGender === "M"
							? " deshabilitado"
							: " deshabilitada"
						: actionCrud === "enable"
						? itemGender === "M"
							? " habilitado"
							: " habilitada"
						: itemGender === "M"
						? " guardado"
						: " guardada");
				response.messageSeverity = "success";
			} else {
				// si hay error controlado
				console.log(_errorMessage);
				console.log(response);

				let _message_code = response.data.message_code;
				if (_message_code === "err-sistema-rol-cu-already-exist") {
					response.messageText = `Ya existe ${unUna} ${nameItemText} con esa descripción o descripción corta`;
				} else {
					response.messageText = _errorMessage;
				}
				response.ok = false;
				response.messageSeverity = "error";
			}
			return response;
		}
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";

		return response;
	}
};

saveAsignation();


//
// #############################################################################
// //

// export const generarPDFSistemasRolesCUs = async (params) => {
// 	let _params = {};
// 	let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;
	
// 	if (params.idSistema) {
// 		_params.id_sistema = params.idSistema;
// 	}
	
// 	if (params.idSistemaRol) {
// 		_params.id_sistema_rol = params.idSistemaRol;
// 	}

// 	//_params.sortfield = 'u.apellido, u.nombre, o.descripcion, area.descripcion';

// 	try {
// 			const response = await axios({
// 				method: "GET",
// 				url: `${urlBackendUsuarios}${epName}`,
// 				params: _params,
// 				headers: {'Authorization': `Bearer ${localStorage.getItem('Token')}`},
// 			});

// 		if (response.status === 200) {
// 			let _error = "error_code" in response.data;
// 			if (!_error) {
// 				// si no hay error controlado
// 				let contenido_json = [];		
// 				contenido_json.push({'data': response.data.data});
// 				response.ok = true;
				
// 				let _paramsPDF = {};
// 				_paramsPDF.tipo = 'pdf';
// 				_paramsPDF.sistema = 'usher';				
// 				_paramsPDF.codigo_reporte = nameReporte;

// 				const responsePDFBuilder = await axios({
// 					method: "PUT",
// 					url: `${urlBackendPDFBuilder}${epPDFBuilderName}`,
// 					params: _paramsPDF,
// 					headers: {
// 						'Authorization': `Bearer ${localStorage.getItem('Token')}`,
// 						'Content-Type': 'application/json'
// 					},
// 					data: { 'contenido_json': contenido_json },
// 					responseType: 'arraybuffer',
// 				});

// 				if(responsePDFBuilder.status === 200){

// 					if (responsePDFBuilder.headers['content-type'] === 'application/json') {
// 						let _error = "error_code" in responsePDFBuilder.data;
// console.log(_error);
// 						} else {

// 							// Crear un objeto Blob a partir de la respuesta
// 							const blob = new Blob([responsePDFBuilder.data], { type: 'application/pdf' });

// 							// Crear una URL de objeto para el Blob
// 							const url = window.URL.createObjectURL(blob);

// 							// Obtener la fecha y hora actual para nombrar el archivo
// 							const fechaHoraActual = new Date();
// 							const formatoFechaHora = `${fechaHoraActual.getFullYear()}${(fechaHoraActual.getMonth() + 1).toString().padStart(2, '0')}${fechaHoraActual.getDate().toString().padStart(2, '0')}_${fechaHoraActual.getHours().toString().padStart(2, '0')}${fechaHoraActual.getMinutes().toString().padStart(2, '0')}`;

// 							// Crear un enlace y simular clic para descargar
// 							const a = document.createElement('a');
// 							a.href = url;
// 							a.download = `${_paramsPDF.sistema}_${_paramsPDF.codigo_reporte}-${formatoFechaHora}.pdf`;
// 							a.click();

// 							// Liberar el objeto URL
// 							window.URL.revokeObjectURL(url);				
							
// 							responsePDFBuilder.ok = true;
// 							return responsePDFBuilder;
// 					}
// 				}

// 			// si hay algun error controlado

// 				console.log(_errorMessage);
// 				console.log(responsePDFBuilder);

// 				responsePDFBuilder.ok = false;
// 				responsePDFBuilder.messageText = _errorMessage;
// 				responsePDFBuilder.messageSeverity = "error";
// 				return responsePDFBuilder;	


// 			}
// 		}

// 		// si hay algun error controlado

// 		console.log(_errorMessage);
// 		console.log(response);

// 		response.ok = false;
// 		response.messageText = _errorMessage;
// 		response.messageSeverity = "error";
// 		return response;
// 	} catch (error) {
// 		console.log(_errorMessage);
// 		if (error.response) {
// 			// The request was made and the server responded with a status code that falls out of the range of 2xx
// 			console.log(error.response);
// 		} else if (error.request) {
// 			// The request was made but no response was received
// 			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
// 			// http.ClientRequest in node.js
// 			console.log(error.request);
// 		} else {
// 			// Something happened in setting up the request that triggered an Error
// 			console.log(error);
// 		}
// 		let response = {};
// 		response.ok = false;
// 		response.messageText = _errorMessage;
// 		response.messageSeverity = "error";
// 		return response;
// 	}
// };
