import { urlBackendUsuarios } from "../core/configs/config";
import "../utils/axios.interceptor";
import HttpService from '../services/HttpService';
HttpService.configure();

const axiosClient = HttpService.getAxiosClient();

const epName = "/usuario-sistema-rol/";

// const nameItemsText = "Usuarios del Sistema";
const nameItemText = "Rol asignado al Usuario de ese Sistema";
const itemGender = "M";
const elLa = itemGender === "M" ? "el" : "la";
const unUna = itemGender === "M" ? "un" : "una";

//
// #############################################################################
//*/
export const saveUsuarioSistemaRol = async (actionCrud, item) => {
	let _errorMessage = `No fue posible guardar ${elLa} ${nameItemText}`;
  let _item = {
		id_sistema: item.idSistema,
		id_sistema_rol: item.idSistemaRol,
		observaciones: item.observaciones,
		id_usuario: item.idUsuario,
		username_actualiza: item.usernameUsuarioActualiza,
	};
	if (actionCrud !== "create") {
		_item.id = item.id;
	}

	try {
		let response = {};
		if (actionCrud === "create") {
			response = await axiosClient.post(`${urlBackendUsuarios}${epName}`, _item, {
        headers: { "Access-Control-Allow-Origin": "*" },
      });
		} else if (actionCrud === "update") {
			response = await axiosClient.put(`${urlBackendUsuarios}${epName}`, _item, {
        headers: { "Access-Control-Allow-Origin": "*" },
      });
		} else if (actionCrud === "disable" || actionCrud === "enable") {
		
			response = await axiosClient.delete(`${urlBackendUsuarios}${epName}`, {
        params: {
          id: _item.id,
          username_actualiza: _item.username_actualiza,
          action: actionCrud,
        },
        headers: { "Access-Control-Allow-Origin": "*" },
      });
		}

		if (response.status === 200) {
    let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				response.messageText =
					`${nameItemText}` +
					(actionCrud === "disable"
						? itemGender === "M"
							? " deshabilitado"
							: " deshabilitada"
						: actionCrud === "enable"
						? itemGender === "M"
							? " habilitado"
							: " habilitada"
						: itemGender === "M"
						? " guardado"
						: " guardada");
          response.messageSeverity = "success";
			} else {
				// si hay error controlado
				console.log(_errorMessage);
				console.log(response);

				let _message_code = response.data.message_code;
        let _message_description = response.data.message_description;
				if (_message_code === "err-usuario-sistema-rol-already-exist") {
					response.messageText = `Ya existe ${unUna} ${nameItemText} con ese username`;
				} else if (_message_description.includes("[email] is not email")) {
					response.messageText = "El email no es válido";
				} else {
					response.messageText = _errorMessage;
				}
        response.ok = false;
        response.messageSeverity = "error";
			}
			return response;
		}
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
    response.messageSeverity = "error";
		return response;
	}
};
