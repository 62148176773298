import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveFueroSubtipoActJuzgado } from "../../../services/FueroSubtipoActJuzgadoService";
import { getSubtiposActJuzgado } from "../../../services/SubtipoActJuzgadoService";



const initialState = {

	visibilidad: "",
	firmaDigital: false,
	firmaElectronica: false,
	listFunciones: [],

};

const visibilidadOptions = [
	{ id: "publico", descripcion: "Público" },
	{ id: "no-publico", descripcion: "No público" },
];


const itemGender = "M";
const nameItemText = "Fuero Subtipo Actuación Juzgado";

//
// ##################################################################################################################### 
//
const FueroSubtipoActJuzgadoDialog = ({
	fueros,

	tipoActJuzgado,
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage,
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [todosSeleccionados, setTodosSeleccionados] = useState(false);

	const [itemFuncionesLocal, setItemFuncionesLocal] = useState(initialState);

	const [subtiposActJuzgadoLocal, setSubtiposActJuzgadoLocal] = useState([]);


	const [valueAutoCompleteFuero, setValueAutoCompleteFuero] = useState(itemCrud.fuero);
	const [inputValueAutoCompleteFuero, setInputValueAutoCompleteFuero] = useState("");

	const [valueAutoCompleteSubtiposActJuzgado, setValueAutoCompleteSubtiposActJuzgado] = useState(itemCrud.subtipoActJuzgado);
	const [inputValueAutoCompleteSubtiposActJuzgado, setInputValueAutoCompleteSubtiposActJuzgado] = useState("");

	const [valueAutoCompleteTipoActJuzgado, setValueAutoCompleteTipoActJuzgado] = useState(itemCrud.tipoActJuzgado)
	const [inputValueAutoCompleteTipoActJuzgado, setInputValueAutoCompleteTipoActJuzgado] = useState("");

	const [checkedFD, setCheckedFD] = useState(itemCrud.firmaDigital);
	const [checkedFE, setCheckedFE] = useState(itemCrud.firmaElectronica);

	useEffect(() => {
		if (actionCrud === "create") {
			setItemCrudLocal(itemCrud);
			setItemFuncionesLocal(itemCrud.listFunciones);
			setValueAutoCompleteFuero("");
			setItemCrudLocal(initialState);
		} else {
			setValueAutoCompleteFuero(itemCrud.fuero);
			setValueAutoCompleteSubtiposActJuzgado(itemCrud.subtipoActJuzgado);
			setValueAutoCompleteTipoActJuzgado(itemCrud.tipoActJuzgado);
			setItemCrudLocal(itemCrud);
			setItemFuncionesLocal(itemCrud.listFunciones);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);

	const handleCloseDialog = () => {
		onClose();
	};

	const handleChange = (e) => {
		let itemsTemp = itemFuncionesLocal;
		itemsTemp[e.target.value].asignado = e.target.checked;
		setItemFuncionesLocal([...itemsTemp]);
	};


	const handleFueroChange = (event, newValue) => {
		setValueAutoCompleteFuero(newValue);
		//loadTipoActJuzgado();
	};



	const toggleSeleccionTodos = () => {
		let itemsTemp1 = itemFuncionesLocal;
		for (let i = 0; i < itemFuncionesLocal.length; i++) {
			itemsTemp1[i].asignado = !todosSeleccionados;
		}
		setItemFuncionesLocal([...itemsTemp1]);
		setTodosSeleccionados(!todosSeleccionados);
	};


	const handleTipoActJuzgadoChange = (event, newValue) => {
		setValueAutoCompleteTipoActJuzgado(newValue);
		loadSubtiposActJuzgado(newValue);
	};

	const loadSubtiposActJuzgado = async (tipoActJuzgado) => {
		let _params = {
			idTipoActJuzgado: tipoActJuzgado?.id || valueAutoCompleteTipoActJuzgado?.id,
		};
		setIsDoingSomething(true);
		const response = await getSubtiposActJuzgado(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _subtiposActJuzgado = [];
			_response.forEach((item) => {
				_subtiposActJuzgado.push({
					id: item.id,
					label: item.descripcion,
				});
			});
			setSubtiposActJuzgadoLocal(_subtiposActJuzgado);
		} else {
			setSubtiposActJuzgadoLocal([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	const handleCheckFD = (e) => {
		setCheckedFD(e.target.checked);
	};

	const handleCheckFE = (e) => {
		setCheckedFE(e.target.checked);
	};

	const handleChangeVisibilidad = (e) => {
		setItemCrudLocal({
			...itemCrudLocal,
			[e.target.name]: e.target.value,
		});
	};


	const handleSave = () => {

		if (actionCrud === "create" || actionCrud === "update") {
			if (inputValueAutoCompleteFuero === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Fuero",
				});
				setShowMessage(true);
				return;
			}

			if (inputValueAutoCompleteTipoActJuzgado === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Tipo Actuación Juzgado",
				});
				setShowMessage(true);
				return;
			}

			if (inputValueAutoCompleteSubtiposActJuzgado === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Subtipo Actuación Juzgado",
				});
				setShowMessage(true);
				return;
			}

			if (itemCrudLocal.visibilidad === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar Visibilidad",
				});
				setShowMessage(true);
				return;
			}


		}

		save();
	};

	const save = async () => {

		let localfuncionesList = [];

		itemFuncionesLocal.forEach(function (item) {
			if (item.asignado) {
				localfuncionesList.push({ id_funcion: item.id_funcion });
			}
		});

		let _itemCrud = {
			idFuero: valueAutoCompleteFuero.id,
			idSubtipoActJuzgado: valueAutoCompleteSubtiposActJuzgado.id,
			visibilidad: itemCrudLocal.visibilidad,
			firmaDigital: checkedFD,
			firmaElectronica: checkedFE,
			listFunciones: localfuncionesList,
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};
		if (actionCrud !== "create") {
			_itemCrud.id = itemCrudLocal.id;
		}
		setIsDoingSomething(true);
		const response = await saveFueroSubtipoActJuzgado(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
			onSave();
		}

		setMessage({
			messageText: response.messageText,
			severity: response.messageSeverity,
		});
		setShowMessage(true);
	};

	const labels = [
		itemCrud.listFunciones.filter((item) => item.habilitado_funcion),
	];


	return (
		<>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}

				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
							? "Editando"
							: actionCrud === "enable"
								? "Habilitando"
								: actionCrud === "read"
									? "Detalle"
									: "Deshabilitando"}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					<Box sx={{ m: 1, p: 1 }}>
						<Autocomplete
							id="fuero"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteFuero}
							onChange={handleFueroChange}

							inputValue={inputValueAutoCompleteFuero}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteFuero(newInputValue);
							}}
							options={fueros}
							renderInput={(params) => (
								<TextField {...params} label="Fuero" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						/>

						<Autocomplete
							id="tipoActJuzgado"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteTipoActJuzgado}
							onChange={handleTipoActJuzgadoChange}
							inputValue={inputValueAutoCompleteTipoActJuzgado}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteTipoActJuzgado(newInputValue);
							}}
							options={tipoActJuzgado}
							renderInput={(params) => (
								<TextField {...params} label="Tipo Actuación Juzgado" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						/>

						<Autocomplete
							id="subtipoActJuzgado"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteSubtiposActJuzgado}
							onChange={(event, newValue) => {
								setValueAutoCompleteSubtiposActJuzgado(newValue);
							}}
							inputValue={inputValueAutoCompleteSubtiposActJuzgado}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteSubtiposActJuzgado(newInputValue);
							}}
							options={subtiposActJuzgadoLocal}
							renderInput={(params) => (
								<TextField {...params} label="Subtipo Actuación Juzgado" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						/>

						<FormControl
							sx={{ width: "100%", pb: 1 }}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						>
							<InputLabel id="visibilidad-select-label">
								Visibilidad
							</InputLabel>
							<Select
								sx={{ width: "100%", mr: 1 }}
								labelId="visibilidad"
								label="Visibilidad"
								id="visibildad"
								name="visibilidad"
								value={itemCrudLocal.visibilidad}
								size="small"
								onChange={handleChangeVisibilidad}
							>
								{visibilidadOptions.map((item) => (
									<MenuItem key={item.id} value={item.id}>
										{item.descripcion}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Checkbox
							sx={{ pb: 1 }}
							name="firmaDigital"
							checked={checkedFD}
							onChange={handleCheckFD}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						/>
						Firma Digital

						<Checkbox
							sx={{ pb: 1 }}
							name="firmaElectronica"
							checked={checkedFE}
							onChange={handleCheckFE}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						/>
						Firma Electrónica

						<Box sx={{ display: "flex" }}>
							<FormControl component="fieldset" variant="standard">
								<FormLabel component="legend">Funciones Firmantes</FormLabel>
								<FormGroup sx={{ m: 3 }}>
									<Grid container spacing={1}>
										<Grid item xs={12} >
											{(actionCrud !== "read") && (<FormControlLabel
												label="SELECCIONAR TODAS"
												control={
													<Checkbox
														position='rigth'
														checked={todosSeleccionados}
														onChange={toggleSeleccionTodos}
														sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
														name="seleccionarTodos"
													/>
												}
											/>
											)}
										</Grid>
										{labels[0].map((item, index) => (
											<Grid item xs={12} key={item.id_funcion}>
												<FormControlLabel
													disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
													control={
														<Checkbox
															checked={item.asignado}
															disabled={!item.habilitado_funcion}
															onChange={handleChange}
															key={item.id_funcion}
															sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
															name="asignado"
															id={item.id_funcion}
															value={index}
															inputProps={{
																"aria-label": "Checkbox A",
															}}
														/>
													}
													label={item.descripcion_funcion}
												/>
											</Grid>
										))}
									</Grid>
								</FormGroup>
							</FormControl>
						</Box>


						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									mt: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Creado: {itemCrudLocal.fechaCreacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
									{itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>
							Cancelar
						</Button>

						{(actionCrud !== "read") && (
							<Button
								sx={{ mr: 1, backgroundcolor: "warning" }}
								variant="contained"
								onClick={handleSave}
								startIcon={
									actionCrud === "disable" ? (
										<RemoveCircleOutlineIcon />
									) : actionCrud === "enable" ? (
										<AddCircleOutlineIcon />
									) : (
										<SaveIcon />
									)
								}
							>
								{actionCrud === "disable"
									? "Deshabilitar"
									: actionCrud === "enable"
										? "Habilitar"
										: "Guardar"}
							</Button>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default FueroSubtipoActJuzgadoDialog;
