import {
	Autocomplete,
	Button,
	FormControl,
	FormControlLabel,
  FormLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Radio,
  RadioGroup,
	Select,
	ThemeProvider,
	TextField,
	Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useEffect, useState } from "react";
import { getOrganismos } from  "../../../services/OrganismoService.js";
import { getFueros } from  "../../../services/FueroService.js";
import OrganismoFueroDialog from "./OrganismoFueroDialog.jsx";
import { getOrganismosFueros, getOrganismoFuero } from  "../../../services/OrganismoFueroService.js";
import Message from  "../../../utils/Message.jsx";
import { Box } from "@mui/system";
import React from 'react';
 

const initialState = {
	estado: "habilitado",
	circunscripcionJudicial: "todo",
	orden: "ASC",
	ordenarPor: "descripcion_fuero",
	rows: 0,
	first: 0,
};

const circunscripcionJudicialOptions = [
	{
		id: "todo",
		descripcion: "Todas",
	},
	{
		id: "1",
		descripcion: "Primera",
	},
	{
		id: "2",
		descripcion: "Segunda",
	},
	{
		id: "3",
		descripcion: "Tercera",
	},
	{
		id: "4",
		descripcion: "Cuarta",
	}
];


const ordenOptions = [
  {
    id: "ASC",
    descripcion: "Ascendente",
  },
  {
    id: "DESC",
    descripcion: "Descendente",
  },
];

const ordenarPorOptions = [
	{
    id: "descripcion_fuero",
    descripcion: "Fuero",
  },
	{
		id: "circunscripcion_judicial_organismo",
		descripcion: "Circunscripción Judicial",
	},
	{
		id: "descripcion_organismo",
		descripcion: "Organismo",
	},
	{
		id: "descripcion_competencia",
		descripcion: "Competencia",
	},
];

const nameItemsText = "Competencias";
//
// ##################################################################################################################### 
//
const OrganismoFueroTable = ({ ...data }) => {
	const [searchData, setSearchData] = useState(initialState);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [fueros, setFueros] = useState([]);
	const [valueAutoCompleteFuero, setValueAutoCompleteFuero] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteFuero, setInputValueAutoCompleteFuero] = useState("");
	const [organismos, setOrganismos] = useState([]);
	const [valueAutoCompleteOrganismo, setValueAutoCompleteOrganismo] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteOrganismo, setInputValueAutoCompleteOrganismo] = useState("");
	const [competencias, setCompetencias] = useState([]);
	const [valueAutoCompleteCompetencia, setValueAutoCompleteCompetencia] = useState({
		id: "",
		label: "",
	});
	const [inputValueAutoCompleteCompetencia, setInputValueAutoCompleteCompetencia] = useState("");
   

 

	const circunscripcionMap = circunscripcionJudicialOptions.reduce((map, option) => {
		map[option.id] = option.descripcion;
		return map;
	}, {});

	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "descripcion_fuero",
			label: "Fuero",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const habilitadoFuero = itemsCrud[tableMeta.rowIndex].habilitado_fuero ? "" : "(Deshabilitado)";
					return `${value} ${habilitadoFuero}`;
				},
			},
		},
		{
			name: 'circunscripcion_judicial_organismo',
			label: 'Circunscripción Judicial',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => circunscripcionMap[value] || value,
			},
		},
		{
			name: "descripcion_organismo",
			label: "Organismo",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const habilitadoOrganismo = itemsCrud[tableMeta.rowIndex].habilitado_organismo ? "" : "(Deshabilitado)";
					return `${value} ${habilitadoOrganismo}`;
				},
			},
		},
		{
			name: "descripcion_competencia",
			label: "Competencia",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "actions",
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					<>

							<Tooltip title="Deshabilitar">
								<IconButton
									size="small"
									aria-label="disable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "disable")}
								>
									<RemoveIcon />
								</IconButton>
							</Tooltip>

              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>

					</>
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 1,
		textLabels: {
			body: {
				noMatch: "No se han encontrado resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};

	//
	// ################################################################################
	//

	const load = async () => {
		searchData.rows = rowsPerPage;
		searchData.first = currentPage * rowsPerPage;
		searchData.sortField = searchData.ordenarPor;
		searchData.sortOrder = searchData.orden; 

		setIsDoingSomething(true);
		const response = await getOrganismosFueros(searchData);
		setIsDoingSomething(false);


		if (response.ok) {
			setItemsCrud(response.data.data);
			setTotalRowsCount(response.data.count);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	//
	// ################################################################################
	//
	useEffect(() => {
		const loadFueros = async () => {
			setIsDoingSomething(true);
			const response = await getFueros({ estado: "habilitado" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _Fueros = [];

				_response.forEach((item) => {
					_Fueros.push({
						id: item.id,
						label: item.descripcion,
					});
				});

				setFueros(_Fueros);
			} else {
				setFueros([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadFueros();
	}, []);


	//
	// ################################################################################
	//

	const loadOrganismos = async (newValue) => {
		let _params = {
			idFuero: newValue?.id || valueAutoCompleteFuero?.id,
			idOrganismo: newValue?.id,
			circunscripcionJudicial: newValue?.circunscripcionJudicial || searchData.circunscripcionJudicial,
			estado: searchData.estado,
			rows: rowsPerPage,
			first: currentPage * rowsPerPage,
		};
		setIsDoingSomething(true);
		const response = await getOrganismos(_params);
		setIsDoingSomething(false);

		if (response.ok) {
			let _response = response.data.data;
			let _Organismos = [];
			_response.forEach((item) => {
				_Organismos.push({
					id: item.id,
					id_fuero: item.id_fuero,
					label: item.descripcion,
				});
			});

			setOrganismos(_Organismos);
		} else {
			setOrganismos([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	//
	// ################################################################################
	//

	useEffect(() => {
		const loadCompetencias = async () => {
			setIsDoingSomething(true);
			const response = await getFueros({ estado: "todos" });
			setIsDoingSomething(false);

			if (response.ok) {
				let _response = response.data.data;
				let _Competencias = [];

				_response.forEach((item) => {
					_Competencias.push({
						id: item.id,
						label: item.descripcion,
					});
				});

				setCompetencias(_Competencias);
			} else {
				setCompetencias([]);
				setMessage({
					messageText: response.messageText,
					severity: response.messageSeverity,
				});
				setShowMessage(true);
			}
		};
		loadCompetencias();
	}, []);
	//
	// ################################################################################
	//

	useEffect(() => {
		load();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, rowsPerPage]);

	const handleChange = (e) => {
		setSearchData({
			...searchData,
			[e.target.name]:
				e.target.type === "checkbox" ? e.target.checked : e.target.value,
		});
	};
	
  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFuero(newValue);
    setSearchData({
      ...searchData,
      idFuero: newValue?.id || '',
    });
    loadOrganismos(newValue);
  };

  const handleCircunscripcionChange = (event) => {
    const newValue = event.target.value;
    setSearchData({
      ...searchData,
      circunscripcionJudicial: newValue,
    });
    loadOrganismos({ circunscripcionJudicial: newValue });
  };

	const search = () => {
		setCurrentPage(0);
		load();
	};

	const clean = () => {
		setValueAutoCompleteFuero({ label: "", id: "" });
		setValueAutoCompleteOrganismo({ label: "", id: "" });
		setValueAutoCompleteCompetencia({ label: "", id: "" });
		setSearchData(initialState);
		setItemsCrud([]);
		setCurrentPage(0);
		setTotalRowsCount(0);
	};

	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getOrganismoFuero({ id: itemCrud.id });
				setIsDoingSomething(false);

				if (response.ok) {
					_itemCrud = {
						id: response.data.data.id,
						fuero: {
							id: response.data.data.id_fuero,
							label: response.data.data.descripcion_fuero,
						},
						organismo: {
							id: response.data.data.id_organismo,
							label: response.data.data.descripcion_organismo,
						},
						competencia: {
							id: response.data.data.id_competencia,
							label: response.data.data.descripcion_competencia,
						},
						circunscripcionJudicialOrganismo: response.data.data.circunscripcion_judicial_organismo,
						fechaCreacion: response.data.data.fecha_creacion,
						nombreUsuario: response.data.data.nombre_usuario,
						apellidoUsuario: response.data.data.apellido_usuario,
						username: response.data.data.username,
					};

					setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {
			_itemCrud = {
				id: '',
				fuero: {
					id: '',
					label: '',
				},
				organismo: {
					id: '',
					label: '',
				},
				competencia: {
					id: '',
					label: '',
				},
				fechaCreacion: '',
				nombreUsuario: '',
				apellidoUsuario: '',
				username: '',
			};

			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};

	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};


	//
	// ################################################################################
	//
	
	// let table = data.data.table

	return (
		<>
			<Paper
				
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Autocomplete
									id="fuero"
									size="small"
									value={valueAutoCompleteFuero}
                  onChange={handleFueroChange}
									inputValue={inputValueAutoCompleteFuero}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteFuero(newInputValue);
									}}
									options={fueros}
									renderInput={(params) => (
										<TextField {...params} label="Fuero" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="circunscripcionJudicial-select-label">Circunscripción Judicial</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="circunscripcionJudicial"
										label="Circunscripción Judicial"
										id="circunscripcionJudicial"
										name="circunscripcionJudicial"
										value={searchData.circunscripcionJudicial}
										size="small"
										onChange={handleCircunscripcionChange}
									>
										{circunscripcionJudicialOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="organismo"
									size="small"
									disabled={!valueAutoCompleteFuero?.id || searchData.circunscripcionJudicial === "todo"}
									value={valueAutoCompleteOrganismo}
									onChange={(event, newValue) => {
										setValueAutoCompleteOrganismo(newValue);
										setSearchData({
											...searchData,
											idOrganismo:
												newValue === null ? "" : newValue.id,
										});
									}}
									inputValue={inputValueAutoCompleteOrganismo}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteOrganismo(newInputValue);
									}}
									options={organismos}
									renderInput={(params) => (
										<TextField {...params} label="Organismo" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<Autocomplete
									id="competencia"
									size="small"
									value={valueAutoCompleteCompetencia}
									onChange={(event, newValue) => {
										setValueAutoCompleteCompetencia(newValue);
										setSearchData({
											...searchData,
											idCompetencia:
												newValue === null ? "" : newValue.id,
										});
									}}
									inputValue={inputValueAutoCompleteCompetencia}
									onInputChange={(event, newInputValue) => {
										setInputValueAutoCompleteCompetencia(newInputValue);
									}}
									options={competencias}
									renderInput={(params) => (
										<TextField {...params} label="Competencia" />
									)}
									isOptionEqualToValue={(option, value) =>
										option.value === value.value
									}
								/>
							</Grid>

						</Grid>
					</Box>
          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="ordenarPor-select-label">
                    Ordenar por:
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="ordenarPor"
                    label="Ordenar por:"
                    id="ordenaPor"
                    name="ordenarPor"
                    value={searchData.ordenarPor}
                    size="small"
                    onChange={handleChange}
                  >
                    {ordenarPorOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
							<Grid item xs={12} md={0.5}>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Orden
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(0, Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        &nbsp;
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={() => handleClickActionCrud({}, "create")}
									startIcon={<AddIcon />}
								>
									Nuevo
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={search}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={clean}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2} >
							</Grid>
							<Grid item xs={12} md={3} lg={2} >
							</Grid>							
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 

					 
						<MUIDataTable columns={tableColumns} data={itemsCrud} options={tableOptions} />
					 
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<OrganismoFueroDialog
					fueros={fueros}
					organismos={organismos}
					competencias={competencias}
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default OrganismoFueroTable;
