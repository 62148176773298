import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  ThemeProvider,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { useEffect, useState } from "react";
import { getFunciones, getFuncion } from  "../../../services/FuncionService";
import FuncionDialog from "./FuncionDialog";
import Message from  "../../../utils/Message";
import { Box } from "@mui/system";
 
import React from "react";
 

const initialSearchData = {
  descripcion: "",
  descripcionCorta: "",
  origenDatos: "todo",
  estado: "habilitado",
  orden: "ASC",
  ordenarPor: "descripcion",
};

const origenDatosOptions = [
  {
    id: "todo",
    descripcion: "Todo",
  },
  {
    id: "manual",
    descripcion: "Manual",
  },
  {
    id: "automatico",
    descripcion: "Automático",
  },
];

const estadoOptions = [
  {
    id: "todo",
    descripcion: "Todo",
  },
  {
    id: "habilitado",
    descripcion: "Habilitado",
  },
  {
    id: "deshabilitado",
    descripcion: "Deshabilitado",
  },
];

const ordenOptions = [
  {
    id: "ASC",
    descripcion: "Ascendente",
  },
  {
    id: "DESC",
    descripcion: "Descendente",
  },
];

const ordenarPorOptions = [
  {
    id: "descripcion",
    descripcion: "Descripción",
  },
  {
    id: "descripcion_corta",
    descripcion: "Descripción Corta",
  },
  {
    id: "origen_datos",
    descripcion: "Origen de Datos",
  },
];

const nameItemsText = "Funciones";
//
// #############################################################################
//
const FuncionTable = ({ ...data }) => {
  const [searchData, setSearchData] = useState(initialSearchData);
  const [itemsCrud, setItemsCrud] = useState([]);
  const [itemCrud, setItemCrud] = useState({});
  const [actionCrud, setActionCrud] = useState("");
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);

  const tableColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "descripcion",
      label: "Descripción",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "descripcion_corta",
      label: "Descripción Corta",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "origen_datos",
      label: "Origen de Datos",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            {itemsCrud[dataIndex].habilitado && (
              <Tooltip title="Editar">
                <IconButton
                  size="small"
                  aria-label="update"
                  onClick={() =>
                    handleClickActionCrud(itemsCrud[dataIndex], "update")
                  }
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}

            {itemsCrud[dataIndex].habilitado && (
              <Tooltip title="Deshabilitar">
                <IconButton
                  size="small"
                  aria-label="disable"
                  onClick={() =>
                    handleClickActionCrud(itemsCrud[dataIndex], "disable")
                  }
                >
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            )}

            {!itemsCrud[dataIndex].habilitado && (
              <Tooltip title="Habilitar">
                <IconButton
                  size="small"
                  aria-label="enable"
                  onClick={() =>
                    handleClickActionCrud(itemsCrud[dataIndex], "enable")
                  }
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}

            {
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() =>
                    handleClickActionCrud(itemsCrud[dataIndex], "read")
                  }
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            }
          </>
        ),
      },
    },
  ];

  const tableOptions = {
    storageKey: "my-table-state",
    rowsPerPageOptions: [10, 20, 30],
    serverSide: true,
    count: totalRowsCount,
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    onChangePage: (currentPage) => {
      setCurrentPage(currentPage);
    },
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 5,
    textLabels: {
      body: {
        noMatch: "No se han encontrado resultados para esta búsqueda",
      },
      pagination: {
        next: "Próxima",
        previous: "Previa",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
  };
  //
  // ################################################################################
  //
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);
  //
  // ################################################################################
  //
  const handleChange = (e) => {
    setSearchData({
      ...searchData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };
  //
  // ################################################################################
  //
  const handleLimpiar = () => {
    setSearchData(initialSearchData);
    setItemsCrud([]);
  };
  //
  // ################################################################################
  //
  const handleBuscar = () => {
    setCurrentPage(0);
    load();
  };
  //
  // ################################################################################
  //
  const load = async () => {
    let _params = {
      descripcion: searchData.descripcion,
      descripcionCorta: searchData.descripcionCorta,
      origenDatos: searchData.origenDatos,
      estado: searchData.estado,
      sortOrder: searchData.orden,
      sortField: searchData.ordenarPor,
      rows: rowsPerPage,
      first: currentPage * rowsPerPage,
    };

    setIsDoingSomething(true);
    const response = await getFunciones(_params);
    setIsDoingSomething(false);

    if (response.ok) {
      setTotalRowsCount(response.data.count);
      setItemsCrud(response.data.data);
    } else {
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };
  //
  // ################################################################################
  //
  const handleClickActionCrud = (itemCrud, action) => {
    let _itemCrud = {};

    if (action !== "create") {
      const loadItem = async () => {
        setIsDoingSomething(true);
        const response = await getFuncion({ id: itemCrud.id });
        setIsDoingSomething(false);

        if (response.ok) {
          _itemCrud = {
            id: itemCrud.id,
            descripcion: response.data.data.descripcion,
            descripcionCorta: response.data.data.descripcion_corta,
            origenDatos: response.data.data.origen_datos,
            fechaCreacion: response.data.data.fecha_creacion,
            fechaActualizacion: response.data.data.fecha_actualizacion,
            nombreUsuario: response.data.data.nombre_usuario,
            apellidoUsuario: response.data.data.apellido_usuario,
            username: response.data.data.username,
          };

          setActionCrud(action);
          setItemCrud(_itemCrud);
          setOpenDialog(true);
        } else {
          setMessage({
            severity: response.messageSeverity,
            messageText: response.messageText,
          });
          setShowMessage(true);
          return;
        }
      };
      loadItem();
    } else {
      setActionCrud(action);
      setItemCrud(_itemCrud);
      setOpenDialog(true);
    }
  };
  //
  // ################################################################################
  //
  const handleDialogSave = () => {
    load();
    setOpenDialog(false);
  };
  //
  // ################################################################################
  //
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  //
  // ################################################################################
  //
  // let table = data.data.table;

  return (
    <>
      <Paper  elevation={2}>
        <Box
          sx={{
            margin: "auto",
            width: "90%",
          }}
        >
          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>{nameItemsText}</h2>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  sx={{ width: "100%", mr: 1 }}
                  id="descripcion"
                  name="descripcion"
                  value={searchData.descripcion}
                  onChange={handleChange}
                  autoFocus={true}
                  label="Descripción"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  sx={{ width: "100%", mr: 1 }}
                  id="descripcionCorta"
                  name="descripcionCorta"
                  value={searchData.descripcionCorta}
                  onChange={handleChange}
                  label="Descripción Corta"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="origen-datos-select-label">
                    Origen de Datos
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="origenDatos"
                    label="Origen de Datos"
                    id="origenDatos"
                    name="origenDatos"
                    value={searchData.origenDatos}
                    size="small"
                    onChange={handleChange}
                  >
                    {origenDatosOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="estado-select-label">Estado</InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="estado"
                    label="Estado"
                    id="estado"
                    name="estado"
                    value={searchData.estado}
                    size="small"
                    onChange={handleChange}
                  >
                    {estadoOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="ordenarPor-select-label">
                    Ordenar por:
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="ordenarPor"
                    label="Ordenar por:"
                    id="ordenaPor"
                    name="ordenarPor"
                    value={searchData.ordenarPor}
                    size="small"
                    onChange={handleChange}
                  >
                    {ordenarPorOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
							<Grid item xs={12} md={0.5}>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Orden
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(0, Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        &nbsp;
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} lg={2}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() => handleClickActionCrud({}, "create")}
                  startIcon={<AddIcon />}
                >
                  Nuevo
                </Button>
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={handleBuscar}
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </Button>
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                <Button
                  sx={{ width: "100%", mr: 1 }}
                  variant="contained"
                  onClick={handleLimpiar}
                  startIcon={<CleaningServicesIcon />}
                >
                  Limpiar
                </Button>
              </Grid>
            </Grid>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 


             
              <MUIDataTable
                columns={tableColumns}
                data={itemsCrud}
                options={tableOptions}
              />
             
          </Box>
        </Box>
      </Paper>

      {/* >>>> Dialog */}

      {openDialog && (
        <FuncionDialog
          onSave={handleDialogSave}
          onClose={handleDialogClose}
          itemCrud={itemCrud}
          actionCrud={actionCrud}
          setMessage={setMessage}
          setShowMessage={setShowMessage}
        />
      )}

      {/* >>>> Messages */}

      {showMessage && (
        <Message
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          message={message}
        />
      )}
    </>
  );
};

export default FuncionTable;
