import { NavLink } from "react-router-dom";
import { Box, Breadcrumbs, Icon, styled, useTheme } from "@mui/material";
import { Home, NavigateNext } from "@mui/icons-material";

// STYLED COMPONENTS
const BreadcrumbRoot = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center"
});

const BreadcrumbName = styled("h4")({
  margin: 0,
  fontSize: "16px",
  paddingBottom: "1px",
  verticalAlign: "middle",
  textTransform: "capitalize"
});

const SubName = styled("span")(({ theme }) => ({
  textTransform: "capitalize",
  color: theme.palette.text.secondary
}));

const Separator = styled("h4")(({ theme }) => ({
  margin: 0,
  marginLeft: 8,
  paddingBottom: "3px",
  color: theme.palette.text.hint
}));

const StyledIcon = styled(Icon)({
  marginLeft: 8,
  marginBottom: "4px",
  verticalAlign: "middle"
});

export default function Breadcrumb({ routeSegments }) {
  const theme = useTheme();
  const hint = theme.palette.text.hint;

  return (
    <BreadcrumbRoot>
      {routeSegments ? (
        <div>
          <BreadcrumbName>{routeSegments[routeSegments.length - 2]["name"]}</BreadcrumbName>
          {/* <Separator>|</Separator> */}
        </div>
      ) : null}

      <Breadcrumbs
        separator={
          <Icon sx={{ color: hint, marginTop: "-5px", marginRight: "-5px" }}>
            <NavigateNext />
          </Icon>
        }
      >
        <NavLink to="/">
          <StyledIcon color="primary" sx={{ marginTop: "-2.5px", marginRight: "-5px" }}>
            <Home sx={{ scale: "0.9" }} />
          </StyledIcon>
        </NavLink>

        {routeSegments
          ? routeSegments.map((route, index) => {
            return index !== routeSegments.length - 1 ? (
              <NavLink key={index} to={route.path}>
                <SubName>{route.name}</SubName>
              </NavLink>
            ) : (
              <SubName key={index}>{route.name}</SubName>
            );
          })
          : null}
      </Breadcrumbs>
    </BreadcrumbRoot>
  );
}
