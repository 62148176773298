import axios from "axios";
import keycloakObject from "../core/utils/keycloak";

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
};

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use(async (config) => {
    let token = keycloakObject.getToken();
    const expirado = keycloakObject.expira();

    if (expirado) {
      try {
        // Intenta actualizar el token si ha expirado
        await keycloakObject.updateToken(() => {
          token = keycloakObject.getToken(); // Actualiza el token después de la actualización
        });
      } catch (error) {
        console.error("Error al actualizar el token: ", error);
        keycloakObject.doLogin(); // Redirige al login si la actualización falla
      }
    }

    // Añade el token (ya sea el original o el actualizado) en la cabecera de autorización
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  }, (error) => {
    return Promise.reject(error);
  });
};

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;
