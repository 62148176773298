import { urlBackendUsuarios } from "../core/configs/config";
import "../utils/axios.interceptor";
import HttpService from '../services/HttpService';
HttpService.configure();

const axiosClient = HttpService.getAxiosClient();

const epName = "/usuario-funcion/";
const nameItemsText = "Usuarios-Funciones";
//const nameItemText = "Usuario-Funcion";
//const itemGender = "M";
//const elLa = itemGender === "M" ? "el" : "la";
//const unUna = itemGender === "M" ? "un" : "una";
//
// #############################################################################
//
export const getUsuariosFunciones = async (params) => {
	let _params = {};
	let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

	if (params.idUsuario) {
		_params.id_usuario = params.idUsuario;
	}

	if (params.idFunciones) {
		_params.id_funcion = params.idFunciones;
	}

	if (params.descripcionCortaFuncion) {
		_params.descripcion_corta_funcion = params.descripcionCortaFuncion;
	}
	
	if (params.estado) {
		let estado =
			params.estado === "habilitado"
				? "H"
				: params.estado === "deshabilitado"
					? "D"
					: "";
		_params.habilitado = estado;
	}

	if (params.rows) {
		_params.rows = params.rows;
	}

	if (params.first) {
		_params.first = params.first;
	}

	if (params.sortField) {
		_params.sortfield = params.sortField;
	}

	if (params.sortOrder) {
		_params.sortorder = params.sortOrder;
	}

	try {
		const response = await axiosClient.get(`${urlBackendUsuarios}${epName}`, {
			params: _params,
			headers: {
				"Access-Control-Allow-Origin": "*",
			},
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				return response;
			}
		}

		// si hay algun error controlado

		console.log(_errorMessage);
		console.log(response);

		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
//
// #############################################################################
//
export const getUsuarioFuncion = async (params) => {
	let _params = {};
	let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

	if (params) {
		if (params.id) {
			_params.id = params.id;
		}
	}

	try {
		const response = await axiosClient.get(`${urlBackendUsuarios}${epName}`, {
			params: params,
			headers: {
				"Access-Control-Allow-Origin": "*",
			},
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {
				// si no hay error controlado
				response.ok = true;
				return response;
			}
		}
		// si hay algun error controlado

		console.log(_errorMessage);
		console.log(response);

		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			console.log(error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error);
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
//
// #############################################################################
//
