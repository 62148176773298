import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { getSistemas } from  "../../../services/SistemaService";
import { getOrganismos } from  "../../../services/OrganismoService";
import { saveSistemaOrganismo } from  "../../../services/SistemaOrganismoService";
 

const initialState = {
	observaciones: "",
	circunscripcionJudicialOrganismo: "",
};

const circunscripcionJudicialOptions = [
  {
    id: "1",
    descripcion: "Primera",
  },
  {
    id: "2",
    descripcion: "Segunda",
  },
  {
    id: "3",
    descripcion: "Tercera",
  },
  {
    id: "4",
    descripcion: "Cuarta",
  }
];

const itemGender = "M";
const nameItemText = "Sistema - Organismo";

const SistemaOrganismoDialog = ({
	fueros,
	organismos,
  sistemas,
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage,
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [organismosLocal, setOrganismosLocal] = useState([]);
	const [sistemasLocal, setSistemasLocal] = useState([]);
	const [valueAutoCompleteFueros, setValueAutoCompleteFueros] = useState(itemCrud.fuero);
	const [inputValueAutoCompleteFueros, setInputValueAutoCompleteFueros] = useState("");
	const [valueAutoCompleteOrganismos, setValueAutoCompleteOrganismos] = useState(itemCrud.organismo);
	const [inputValueAutoCompleteOrganismos, setInputValueAutoCompleteOrganismos] = useState("");
	const [valueAutoCompleteSistemas, setValueAutoCompleteSistemas] = useState(itemCrud.sistema);
	const [inputValueAutoCompleteSistemas, setInputValueAutoCompleteSistemas] = useState("");

	useEffect(() => {
		if (actionCrud === "create") {
			setValueAutoCompleteFueros("");
			setItemCrudLocal(initialState);
		} else {
			setValueAutoCompleteFueros(itemCrud.fuero);
			setValueAutoCompleteOrganismos(itemCrud.organismo);
			setValueAutoCompleteSistemas(itemCrud.sistema);
			setItemCrudLocal(itemCrud);
			loadOrganismos(itemCrud.fuero, itemCrud.circunscripcionJudicialOrganismo)
			loadSistemas(itemCrud.organismo)
		}
	}, []);

	const handleCloseDialog = () => {
		onClose();
	};

	const handleChange = (e) => {
		setItemCrudLocal({
			...itemCrudLocal,
			[e.target.name]: e.target.value,
		});
	};

	const handleCircunscripcionChange = (event) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      circunscripcionJudicialOrganismo: event.target.value,
    });
    setValueAutoCompleteOrganismos("");
    setValueAutoCompleteSistemas("");
    loadOrganismos(valueAutoCompleteFueros, event.target.value);
		loadSistemas(itemCrud.organismo);
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFueros(newValue);
    setValueAutoCompleteOrganismos("");
    setValueAutoCompleteSistemas("");
    loadOrganismos(newValue, itemCrudLocal.circunscripcionJudicialOrganismo);
		loadSistemas(itemCrud.organismo);
  };

	const handleSave = () => {
		if (actionCrud === "create" || actionCrud === "update") {		
			if (inputValueAutoCompleteFueros === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Fuero",
				});
				setShowMessage(true);
				return;
			}

			if (inputValueAutoCompleteOrganismos === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Organismo",
				});
				setShowMessage(true);
				return;
			}

			if (inputValueAutoCompleteSistemas === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Sistema",
				});
				setShowMessage(true);
				return;
			}
		}
		save();
	};

	const save = async () => {
		let _itemCrud = {
			idFuero: valueAutoCompleteFueros?.id,
			idOrganismo: valueAutoCompleteOrganismos?.id,
			idSistema: valueAutoCompleteSistemas?.id,
			observaciones: itemCrudLocal?.observaciones,
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};
		if (actionCrud !== "create") {
			_itemCrud.id = itemCrud.id;
		}
		setIsDoingSomething(true);
		const response = await saveSistemaOrganismo(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
			onSave();
	}

	setMessage({
		messageText: response.messageText,
		severity: response.messageSeverity,
	});
	setShowMessage(true);
	};
 
	const loadOrganismos = async (fuero, circunscripcionJudicial) => {
		let _params = {
      idFuero: fuero?.id || valueAutoCompleteFueros?.id,
      circunscripcionJudicial: circunscripcionJudicial || itemCrudLocal.circunscripcionJudicialOrganismo,
		};
		setIsDoingSomething(true);

		const response = await getOrganismos(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _organismos = [];
			_response.forEach((item) => {
				_organismos.push({
					id: item.id,
					label: item.descripcion,
				});
			});
			setOrganismosLocal(_organismos);
		} else {
			setOrganismosLocal([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};

	const loadSistemas = async () => {
		let _params = {
			estado: 'habilitado',
		};
		setIsDoingSomething(true);
		const response = await getSistemas(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _sistemas = [];
			_response.forEach((item) => {
				_sistemas.push({
					id: item.id,
					label: item.descripcion,
				});
			});
			setSistemasLocal(_sistemas);
		} else {
			setSistemasLocal([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	
	return (
		<>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					 
				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
							? "Editando"
							: actionCrud === "enable"
								? "Habilitando"
								: actionCrud === "disable"
									? "Deshabilitando"
									: actionCrud === "read"
										? "Detalle"
										: ""}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					<Box sx={{ m: 1, p: 1 }}>
						<Autocomplete
							id="fuero"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteFueros}
              onChange={handleFueroChange}
							inputValue={inputValueAutoCompleteFueros}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteFueros(newInputValue);
							}}
							options={fueros}
							renderInput={(params) => (
								<TextField {...params} label="Fuero" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							disableClearable = {true}
						/>
            <FormControl
              sx={{ width: "100%", pb: 1 }}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
            >
              <InputLabel id="circunscripcionJudicial-select-label">
                Circunscripción Judicial
              </InputLabel>
              <Select
                sx={{ width: "100%", mr: 1 }}
                labelId="circunscripcionJudicial"
                label="Circunscripción Judicial"
                id="circunscripcionJudicialOrganismo"
                name="circunscripcionJudicial"
                value={itemCrudLocal.circunscripcionJudicialOrganismo}
                size="small"
                onChange={handleCircunscripcionChange}
              >
                {circunscripcionJudicialOptions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
						<Autocomplete
							id="organismo"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteOrganismos}
							onChange={(event, newValue) => {
								setValueAutoCompleteOrganismos(newValue);
								setValueAutoCompleteSistemas("");
							}}
							inputValue={inputValueAutoCompleteOrganismos}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteOrganismos(newInputValue);
							}}
							options={organismosLocal}
							renderInput={(params) => (
								<TextField {...params} label="Organismo" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read" || !valueAutoCompleteFueros?.id || !itemCrudLocal.circunscripcionJudicialOrganismo}
						/>

						<Autocomplete
							id="sistema"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteSistemas}
							onChange={(event, newValue) => {
								setValueAutoCompleteSistemas(newValue);
							}}
							inputValue={inputValueAutoCompleteSistemas}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteSistemas(newInputValue);
							}}
							options={sistemasLocal}
							renderInput={(params) => (
								<TextField {...params} label="Sistema" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="observaciones"
							label="Observaciones"
							value={itemCrudLocal.observaciones}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
						/>

						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									mt: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
									{itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>									
							Cancelar
						</Button>

						{(actionCrud !== "read") && (	
							<Button
							sx={{ mr: 1, backgroundcolor: "warning" }}
							variant="contained"
							onClick={handleSave}
							startIcon={
								actionCrud === "disable" ? (
									<RemoveCircleOutlineIcon />
								) : actionCrud === "enable" ? (
									<AddCircleOutlineIcon />
								) : (
									<SaveIcon />
								)
							}
						>
							{actionCrud === "disable"
								? "Deshabilitar"
								: actionCrud === "enable"
								? "Habilitar"
								: "Guardar"}
						</Button>
						)}
						</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default SistemaOrganismoDialog;
