import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  ThemeProvider,
  TextField,
  Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { useEffect, useState } from "react";
import { getProcesos } from "../../../services/ProcesoService.js";
import { getMaterias, getMateria } from "../../../services/MateriaService.js";
import MateriaDialog from "./MateriaDialog.jsx";
import { getFueros } from "../../../services/FueroService.js";
import Message from "../../../utils/Message.jsx";
import { Box } from "@mui/system";
import React from "react";


const initialState = {
  descripcion: "",
  descripcionCorta: "",
  estado: "habilitado",
  visibilidad: "todo",
  orden: "ASC",
  ordenarPor: "descripcion",
  rows: 0,
  first: 0,
};

const estadoOptions = [
  {
    id: "todo",
    descripcion: "Todo",
  },
  {
    id: "habilitado",
    descripcion: "Habilitado",
  },
  {
    id: "deshabilitado",
    descripcion: "Deshabilitado",
  },
];

const visibilidadOptions = [
  {
    id: "todo",
    descripcion: "Todo",
  },
  {
    id: "publico",
    descripcion: "Público",
  },
  {
    id: "no-publico",
    descripcion: "No Público",
  },
];

const ordenOptions = [
  {
    id: "ASC",
    descripcion: "Ascendente",
  },
  {
    id: "DESC",
    descripcion: "Descendente",
  },
];

const ordenarPorOptions = [
  {
    id: "descripcion",
    descripcion: "Materia",
  },
  {
    id: "descripcion_corta",
    descripcion: "Descripción Corta",
  },
  {
    id: "descripcion_fuero",
    descripcion: "Fuero",
  },
  {
    id: "descripcion_proceso",
    descripcion: "Proceso",
  },
];

const nameItemsText = "Materias";
//
// #####################################################################################################################
//
const MateriaTable = ({ ...data }) => {
  const [searchData, setSearchData] = useState(initialState);
  const [itemsCrud, setItemsCrud] = useState([]);
  const [itemCrud, setItemCrud] = useState({});
  const [actionCrud, setActionCrud] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fueros, setFueros] = useState([]);
  const [valueAutoCompleteFuero, setValueAutoCompleteFuero] = useState({
    id: "",
    label: "",
  });
  const [inputValueAutoCompleteFuero, setInputValueAutoCompleteFuero] = useState("");
  const [procesos, setProcesos] = useState([]);
  const [valueAutoCompleteProceso, setValueAutoCompleteProceso] = useState({
    id: "",
    label: "",
  });
  const [inputValueAutoCompleteProceso, setInputValueAutoCompleteProceso] =
    useState("");

  const tableColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "descripcion",
      label: "Materia",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "descripcion_corta",
      label: "Descripción Corta",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "descripcion_fuero",
      label: "Fuero",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const habilitadoFuero = itemsCrud[tableMeta.rowIndex].habilitado_fuero
            ? ""
            : "Deshabilitado";
          return `${value} ${habilitadoFuero}`;
        },
      },
    },
    {
      name: "descripcion_proceso",
      label: "Proceso",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const habilitadoProceso = itemsCrud[tableMeta.rowIndex]
            .habilitado_proceso
            ? ""
            : "(Deshabilitado)";
          return `${value} ${habilitadoProceso}`;
        },
      },
    },
    {
      name: "visibilidad",
      label: "Visibilidad",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "solicita_monto",
      label: "Solicita Monto",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const solicitaMonto = itemsCrud[tableMeta.rowIndex].solicita_monto
            ? "Sí"
            : "No";
          return solicitaMonto;
        },
      },
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
            {itemsCrud[dataIndex].habilitado_proceso &&
              itemsCrud[dataIndex].habilitado_fuero &&
              itemsCrud[dataIndex].habilitado && (
                <Tooltip title="Editar">
                  <IconButton
                    size="small"
                    aria-label="update"
                    onClick={() =>
                      handleClickActionCrud(itemsCrud[dataIndex], "update")
                    }
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}

            {itemsCrud[dataIndex].habilitado_proceso &&
              itemsCrud[dataIndex].habilitado_fuero &&
              itemsCrud[dataIndex].habilitado && (
                <Tooltip title="Deshabilitar">
                  <IconButton
                    size="small"
                    aria-label="disable"
                    onClick={() =>
                      handleClickActionCrud(itemsCrud[dataIndex], "disable")
                    }
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
              )}

            {itemsCrud[dataIndex].habilitado_proceso &&
              itemsCrud[dataIndex].habilitado_fuero &&
              !itemsCrud[dataIndex].habilitado && (
                <Tooltip title="Habilitar">
                  <IconButton
                    size="small"
                    aria-label="enable"
                    onClick={() =>
                      handleClickActionCrud(itemsCrud[dataIndex], "enable")
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}

            {
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() =>
                    handleClickActionCrud(itemsCrud[dataIndex], "read")
                  }
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            }
          </>
        ),
      },
    },
  ];

  const tableOptions = {
    storageKey: "my-table-state",
    rowsPerPageOptions: [10, 20, 30],
    serverSide: true,
    count: totalRowsCount,
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    onChangePage: (currentPage) => {
      setCurrentPage(currentPage);
    },
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 1,
    textLabels: {
      body: {
        noMatch: "No se han encontrado resultados para esta búsqueda",
      },
      pagination: {
        next: "Próxima",
        previous: "Previa",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
  };
  //
  // ################################################################################
  //
  const load = async () => {
    searchData.rows = rowsPerPage;
    searchData.first = currentPage * rowsPerPage;
    searchData.sortField = searchData.ordenarPor;
    searchData.sortOrder = searchData.orden;

    setIsDoingSomething(true);
    const response = await getMaterias(searchData);
    setIsDoingSomething(false);

    if (response.ok) {
      setItemsCrud(response.data.data);
      setTotalRowsCount(response.data.count);
    } else {
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  //
  // ################################################################################
  //

  const loadProcesos = async (newValue) => {
    let _params = {
      idFuero: newValue?.id || valueAutoCompleteFuero?.id,
      estado: searchData.estado,
      rows: rowsPerPage,
      first: currentPage * rowsPerPage,
    };

    setIsDoingSomething(true);
    const response = await getProcesos(_params);
    setIsDoingSomething(false);

    if (response.ok) {
      let _response = response.data.data;
      let _procesos = [];

      _response.forEach((item) => {
        _procesos.push({
          id: item.id,
          id_fuero: item.id_fuero,
          label: item.descripcion,
        });
      });

      setProcesos(_procesos);
    } else {
      setProcesos([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  //
  // ################################################################################
  //
  useEffect(() => {
    const loadFueros = async () => {
      setIsDoingSomething(true);
      const response = await getFueros({ estado: "habilitado" });
      setIsDoingSomething(false);

      if (response.ok) {
        let _response = response.data.data;
        let _Fueros = [];

        _response.forEach((item) => {
          _Fueros.push({
            id: item.id,
            label: item.descripcion,
          });
        });

        setFueros(_Fueros);
      } else {
        setFueros([]);
        setMessage({
          messageText: response.messageText,
          severity: response.messageSeverity,
        });
        setShowMessage(true);
      }
    };
    loadFueros();
  }, []);

  //
  // ################################################################################
  //

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);

  const handleChange = (e) => {
    setSearchData({
      ...searchData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFuero(newValue);
    setSearchData({
      ...searchData,
      idFuero: newValue?.id || "",
    });
    loadProcesos(newValue);
  };

  const search = () => {
    setCurrentPage(0);
    load();
  };

  const clean = () => {
    setValueAutoCompleteFuero({ label: "", id: "" });
    setValueAutoCompleteProceso({ label: "", id: "" });
    setSearchData(initialState);
    setItemsCrud([]);
  };

  const handleClickActionCrud = (itemCrud, action) => {
    let _itemCrud = {};

    if (action !== "create") {
      const loadItem = async () => {
        setIsDoingSomething(true);
        const response = await getMateria({ id: itemCrud.id });
        setIsDoingSomething(false);

        if (response.ok) {
          _itemCrud = {
            id: response.data.data.id,
            fuero: {
              id: response.data.data.id_fuero,
              label: response.data.data.descripcion_fuero,
            },
            proceso: {
              id: response.data.data.id_proceso,
              label: response.data.data.descripcion_proceso,
            },
            descripcion: response.data.data.descripcion,
            descripcionCorta: response.data.data.descripcion_corta,
            visibilidad: response.data.data.visibilidad,
            solicitaMonto: response.data.data.solicita_monto,
            observaciones: response.data.data.observaciones,
            fechaCreacion: response.data.data.fecha_creacion,
            fechaActualizacion: response.data.data.fecha_actualizacion,
            nombreUsuario: response.data.data.nombre_usuario,
            apellidoUsuario: response.data.data.apellido_usuario,
            username: response.data.data.username,
          };

          setActionCrud(action);
          setItemCrud(_itemCrud);
          setOpenDialog(true);
        } else {
          setMessage({
            severity: response.messageSeverity,
            messageText: response.messageText,
          });
          setShowMessage(true);
          return;
        }
      };
      loadItem();
    } else {
      setActionCrud(action);
      setItemCrud(_itemCrud);
      setOpenDialog(true);
    }
  };

  const handleDialogSave = () => {
    load();
    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  //
  // ################################################################################
  // let table = data.data.table

  return (
    <>
      <Paper elevation={2}>
        <Box
          sx={{
            margin: "auto",
            width: "90%",
          }}
        >
          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>{nameItemsText}</h2>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  sx={{ width: "100%", mr: 1 }}
                  id="descripcion"
                  name="descripcion"
                  value={searchData.descripcion}
                  onChange={handleChange}
                  autoFocus={true}
                  label="Materia"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  sx={{ width: "100%", mr: 1 }}
                  id="descripcionCorta"
                  name="descripcionCorta"
                  value={searchData.descripcionCorta}
                  onChange={handleChange}
                  autoFocus={true}
                  label="Descripción Corta"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <Autocomplete
                  id="fuero"
                  size="small"
                  value={valueAutoCompleteFuero}
                  onChange={handleFueroChange}
                  inputValue={inputValueAutoCompleteFuero}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoCompleteFuero(newInputValue);
                  }}
                  options={fueros}
                  renderInput={(params) => (
                    <TextField {...params} label="Fuero" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <Autocomplete
                  id="proceso"
                  size="small"
                  disabled={!valueAutoCompleteFuero?.id}
                  value={valueAutoCompleteProceso}
                  onChange={(event, newValue) => {
                    setValueAutoCompleteProceso(newValue);
                    setSearchData({
                      ...searchData,
                      idProceso: newValue === null ? "" : newValue.id,
                    });
                  }}
                  inputValue={inputValueAutoCompleteProceso}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoCompleteProceso(newInputValue);
                  }}
                  options={procesos}
                  renderInput={(params) => (
                    <TextField {...params} label="Proceso" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="visibilidad-select-label">
                    Visibilidad
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="visibilidad"
                    label="Visibilidad"
                    id="visibilidad"
                    name="visibilidad"
                    value={searchData.visibilidad}
                    size="small"
                    onChange={handleChange}
                  >
                    {visibilidadOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="estado-select-label">Estado</InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="estado"
                    label="Estado"
                    id="estado"
                    name="estado"
                    value={searchData.estado}
                    size="small"
                    onChange={handleChange}
                  >
                    {estadoOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="ordenarPor-select-label">
                    Ordenar por:
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="ordenarPor"
                    label="Ordenar por:"
                    id="ordenaPor"
                    name="ordenarPor"
                    value={searchData.ordenarPor}
                    size="small"
                    onChange={handleChange}
                  >
                    {ordenarPorOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={0.5}></Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Orden
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(0, Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        &nbsp;
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} lg={2}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() => handleClickActionCrud({}, "create")}
                  startIcon={<AddIcon />}
                >
                  Nuevo
                </Button>
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={search}
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </Button>
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                <Button
                  sx={{ width: "100%", mr: 1 }}
                  variant="contained"
                  onClick={clean}
                  startIcon={<CleaningServicesIcon />}
                >
                  Limpiar
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ m: 1, p: 1 }}></Box>
          {isDoingSomething && <LinearProgress />}

          <MUIDataTable
            columns={tableColumns}
            data={itemsCrud}
            options={tableOptions}
          />

        </Box>
      </Paper>

      {/* >>>> Dialog */}

      {openDialog && (
        <MateriaDialog
          fueros={fueros}
          procesos={procesos}
          onSave={handleDialogSave}
          onClose={handleDialogClose}
          itemCrud={itemCrud}
          actionCrud={actionCrud}
          setMessage={setMessage}
          setShowMessage={setShowMessage}
        />
      )}

      {/* >>>> Messages */}

      {showMessage && (
        <Message
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          message={message}
        />
      )}
    </>
  );
};

export default MateriaTable;
