import axios from 'axios';
//
// #############################################################################
//
axios.interceptors.request.use(
  (request) => {
    // Aquí puedes modificar la configuración de la solicitud antes de enviarla.

    // let _token = localStorage.getItem("token");

    let _token = localStorage.getItem("token");
    // let _token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJPTFAiLCJpYXQiOjE2ODk1OTM0OTcsImV4cCI6MTY4OTY0MDI5NywiYXVkIjoiT0xQIiwic3ViIjoibWFpbEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6Ik1pZ3VlbCIsIlN1cm5hbWUiOiJSYWd1c2EiLCJFbWFpbCI6Im1ocmFndXNhQGdtYWlsLmNvbSIsImlkX3VzdWFyaW8iOiIwODFkOWE4NC00MzBkLTQ1MjYtYmE5OS1jMWQ4M2QzOTc5YTIiLCJQZXJtaXNzaW9ucyI6W3sic3lzdGVtIjoiVHVybm9zIiwicm9sZXMiOltbIkVuY2FyZ2FkbyJdLFsiT3BlcmFkb3IiXSxbIlN1cGVydmlzb3IiXV0sInVzZV9jYXNlcyI6W1siT1MtUkVHSVNUUkFDSU9OIl0sWyJHRVNUSU9OIl0sWyJUQy1TVUNVUlNBTCJdLFsiT1MtQ09CUkFOWkEiXSxbIk9TLUVESUNJT04tT1MiXSxbIk9TLUVESUNJT04tUFJFU1RBQ0lPTiJdLFsiVEMtQ09OVFJPTCJdLFsiT1MtRURJQ0lPTi1QTEFOIl0sWyJUQy1FRElDSU9OIl0sWyJHRVNUSU9OIl0sWyJPUy1QUkVTRU5UQUNJT04tQURNSU5JU1RSQUNJT04iXSxbIk9TLUNPTlRST0wiXSxbIk9TLUNPQlJBTlpBIl0sWyJPUy1FRElDSU9OLU9TIl0sWyJPUy1FRElDSU9OLVBSRVNUQUNJT04iXSxbIlRDLUNPTlRST0wiXSxbIk9TLUVESUNJT04tUExBTiJdLFsiVEMtRURJQ0lPTiJdLFsiR0VTVElPTiJdLFsiT1MtUFJFU0VOVEFDSU9OLUFETUlOSVNUUkFDSU9OIl0sWyJPUy1DT05UUk9MIl1dfSx7InN5c3RlbSI6IkFkbWluaXN0cmFjaW9uLVRjLU9zIiwicm9sZXMiOltbIkVuY2FyZ2FkbyJdLFsiT3BlcmFkb3IiXSxbIlN1cGVydmlzb3IiXV0sInVzZV9jYXNlcyI6W1siT1MtUkVHSVNUUkFDSU9OIl0sWyJHRVNUSU9OIl0sWyJUQy1TVUNVUlNBTCJdLFsiT1MtQ09CUkFOWkEiXSxbIk9TLUVESUNJT04tT1MiXSxbIk9TLUVESUNJT04tUFJFU1RBQ0lPTiJdLFsiVEMtQ09OVFJPTCJdLFsiT1MtRURJQ0lPTi1QTEFOIl0sWyJUQy1FRElDSU9OIl0sWyJHRVNUSU9OIl0sWyJPUy1QUkVTRU5UQUNJT04tQURNSU5JU1RSQUNJT04iXSxbIk9TLUNPTlRST0wiXSxbIk9TLUNPQlJBTlpBIl0sWyJPUy1FRElDSU9OLU9TIl0sWyJPUy1FRElDSU9OLVBSRVNUQUNJT04iXSxbIlRDLUNPTlRST0wiXSxbIk9TLUVESUNJT04tUExBTiJdLFsiVEMtRURJQ0lPTiJdLFsiR0VTVElPTiJdLFsiT1MtUFJFU0VOVEFDSU9OLUFETUlOSVNUUkFDSU9OIl0sWyJPUy1DT05UUk9MIl1dfV19.rX-FZnIlhLeUXYLNULbQ7a-Jz9pPz_31KVr6DAFVtcMtQxr1EdHLNJNQFOquMPMLkJ_r4luxK4Ij7_r7srGPSe7L5CJIuDz8QJ_1fpVAcyn56PvKGhSHkzXBK0WZSbhq-yMGgSWlYQSOdcJw0vcVj7dOpCzDLoXtNjem_ux47rcQ5utI3YNbd1JE6zBrLnz3B9XRdYDIbTIGwImH2E-KBur33DBFg1p55L604Cxg58PGLZJ_Vq7Bnm8x2yf1Mpo24i5lWNcAyqT_YxNTaHUzL4V6RxSIpoKa22g3uh-7c9iIP6mdSWQ_vr46OaR3a2GZq8imjPmpZ3rBkTbvcYYDAA";

    if (_token) {
      request.headers["Authorization"] = `Bearer ${_token}`
    }
    return request;
  },
  (error) => {
    // Aquí puedes manejar errores en la solicitud.
    return Promise.reject(error);
  }
);
//
// #############################################################################
//
axios.interceptors.response.use(
  (response) => {
    // Aquí puedes modificar la respuesta antes de enviarla al componente.
    return response;
  },
  (error) => {
    // Aquí puedes manejar errores en la respuesta.
    return Promise.reject(error);
  }
);