import React from 'react';
import MUIDataTable from 'mui-datatables';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Container, Button, Typography, Box } from '@mui/material';
import { AddCircle, AllInbox, Description, Info, LibraryAdd, MenuBook, PostAdd, Visibility } from "@mui/icons-material";
import TituloContenido from '../../elements/TituloContenido';

const Dashboard = () => {
  const navigate = useNavigate();

  const columns = [
    { name: 'numero', label: 'Número' },
    { name: 'caratula', label: 'Carátula' },
    { name: 'materia', label: 'Materia' },
    { name: 'activo', label: 'Activo', options: { customBodyRender: (value) => (value ? 'Sí' : 'No') } },
    { name: 'fecha_inicio', label: 'Fecha de Inicio' },
    {
      name: 'id',
      label: 'Ficha',
      options: {
        customBodyRender: (value) => (
          <Button
            variant="contained"
            size="small"
            startIcon={<Visibility />}
            onClick={() => window.open(`/expediente/${value}`, '_blank')}
          >Ver
          </Button>
        ),
      },
    },
  ];

  const HeaderElements = () => (
    <>
      <Button
        sx={{ background: "green", padding: "7.5px 10px" }}
        variant="contained"
        size="small"
      >
        <LibraryAdd sx={{ mr: 1 }} />
        <b>Agregar</b>
      </Button>
    </>
  );

  return (
    <Container maxWidth="xg" >

    </Container>
  )
};

export default Dashboard;
