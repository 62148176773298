import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveOrganismo } from "../../../services/OrganismoService";



const initialState = {
	descripcion: "",
	descripcionCorta: "",
	circunscripcionJudicial: "",
	instancia: "",
	origenDatos: "manual",
	codigoExterno: "",
	domicilio: "",
	telefono: "",
	eMail: "",
};

const origenDatosOptions = [
	{
		id: "manual",
		descripcion: "Manual",
	},
	{
		id: "automatico",
		descripcion: "Automático",
	},
];


const circunscripcionJudicialOptions = [
	{
		id: "1",
		descripcion: "Primera",
	},
	{
		id: "2",
		descripcion: "Segunda",
	},
	{
		id: "3",
		descripcion: "Tercera",
	},
	{
		id: "4",
		descripcion: "Cuarta",
	}
];

const instanciaOptions = [
	{
		id: "primera",
		descripcion: "Primera",
	},
	{
		id: "segunda",
		descripcion: "Segunda",
	},
	{
		id: "tercera",
		descripcion: "Tercera",
	},
];

const itemGender = "M";
const nameItemText = "Organismo";

//
// ##################################################################################################################### 
//
const OrganismoDialog = ({
	fueros,
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage,
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [valueAutoCompleteFuero, setValueAutoCompleteFuero] = useState(itemCrud.fuero);
	const [inputValueAutoCompleteFuero, setInputValueAutoCompleteFuero] = useState("");

	useEffect(() => {
		if (actionCrud === "create") {
			setValueAutoCompleteFuero("");
			setItemCrudLocal(initialState);
		} else {
			setValueAutoCompleteFuero(itemCrud.fuero);
			setItemCrudLocal(itemCrud);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);

	const handleCloseDialog = () => {
		onClose();
	};

	const handleChange = (e) => {
		setItemCrudLocal({
			...itemCrudLocal,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = () => {
		if (actionCrud === "create" || actionCrud === "update") {
			if (inputValueAutoCompleteFuero === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Fuero",
				});
				setShowMessage(true);
				return;
			}

			if (itemCrudLocal.descripcion.trim() === "") {
				setMessage({
					severity: "error",
					messageText: "Debes ingresar una descripción",
				});
				setShowMessage(true);
				return;
			}
		}

		if (actionCrud === "create") {
			if (itemCrudLocal.descripcionCorta.trim() === "") {
				setMessage({
					severity: "error",
					messageText: "Debes ingresar una descripción corta",
				});
				setShowMessage(true);
				return;
			}
		}

		save();
	};

	const save = async () => {
		let _itemCrud = {
			idFuero: valueAutoCompleteFuero.id,
			circunscripcionJudicial: itemCrudLocal.circunscripcionJudicial,
			instancia: itemCrudLocal.instancia,
			descripcion: itemCrudLocal.descripcion,
			origenDatos: itemCrudLocal.origenDatos,
			codigoExterno: itemCrudLocal.codigoExterno,
			domicilio: itemCrudLocal.domicilio,
			telefono: itemCrudLocal.telefono,
			eMail: itemCrudLocal.eMail,
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};
		if (actionCrud !== "create") {
			_itemCrud.id = itemCrudLocal.id;
		}
		if (actionCrud === "create") {
			_itemCrud.descripcionCorta = itemCrudLocal.descripcionCorta;
		}

		setIsDoingSomething(true);
		const response = await saveOrganismo(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
			onSave();
		}

		setMessage({
			messageText: response.messageText,
			severity: response.messageSeverity,
		});
		setShowMessage(true);
	};

	return (
		<>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}

				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
							? "Editando"
							: actionCrud === "enable"
								? "Habilitando"
								: actionCrud === "read"
									? "Detalle"
									: "Deshabilitando"}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					<Box sx={{ m: 1, p: 1 }}>
						<Autocomplete
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteFuero}
							onChange={(event, newValue) => {
								setValueAutoCompleteFuero(newValue);
							}}
							inputValue={inputValueAutoCompleteFuero}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteFuero(newInputValue);
							}}
							id="fuero"
							options={fueros}
							renderInput={(params) => (
								<TextField {...params} label="Fuero" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						/>
						<FormControl
							sx={{ width: "100%", pb: 1 }}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						>
							<InputLabel id="circunscripcionJudicial-select-label">
								Circunscripción Judicial
							</InputLabel>
							<Select
								sx={{ width: "100%", mr: 1 }}
								labelId="circunscripcionJudicial"
								label="Circunscripción Judicial"
								id="circunscripcionJudicial"
								name="circunscripcionJudicial"
								value={itemCrudLocal.circunscripcionJudicial}
								size="small"
								onChange={handleChange}
							>
								{circunscripcionJudicialOptions.map((item) => (
									<MenuItem key={item.id} value={item.id}>
										{item.descripcion}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<TextField
							variant="outlined"
							size="small"
							name="descripcion"
							label="Descripción"
							value={itemCrudLocal.descripcion}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="descripcionCorta"
							label="Descripción Corta"
							value={itemCrudLocal.descripcionCorta}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "update" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						<FormControl
							sx={{ width: "100%", pb: 1 }}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						>
							<InputLabel id="instancia-select-label">
								Instancia
							</InputLabel>
							<Select
								sx={{ width: "100%", mr: 1 }}
								labelId="instancia"
								label="Instancia"
								id="instancia"
								name="instancia"
								value={itemCrudLocal.instancia}
								size="small"
								onChange={handleChange}
							>
								{instanciaOptions.map((item) => (
									<MenuItem key={item.id} value={item.id}>
										{item.descripcion}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl
							sx={{ width: "100%", pb: 1 }}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						>
							<InputLabel id="origen-datos-select-label">
								Origen de Datos
							</InputLabel>
							<Select
								sx={{ width: "100%", mr: 1 }}
								labelId="origenDatos"
								label="Origen de Datos"
								id="origenDatos"
								name="origenDatos"
								value={itemCrudLocal.origenDatos}
								size="small"
								onChange={handleChange}
							>
								{origenDatosOptions.map((item) => (
									<MenuItem key={item.id} value={item.id}>
										{item.descripcion}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<TextField
							variant="outlined"
							size="small"
							name="codigoExterno"
							label="Código Externo"
							value={itemCrudLocal.codigoExterno}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 20 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="domicilio"
							label="Domicilio"
							value={itemCrudLocal.domicilio}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="telefono"
							label="Teléfono"
							value={itemCrudLocal.telefono}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 30 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="eMail"
							label="Email"
							value={itemCrudLocal.eMail}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 61 }}
						/>

						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									mt: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Creado: {itemCrudLocal.fechaCreacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
									{itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>
							Cancelar
						</Button>

						{(actionCrud !== "read") && (
							<Button
								sx={{ mr: 1, backgroundcolor: "warning" }}
								variant="contained"
								onClick={handleSave}
								startIcon={
									actionCrud === "disable" ? (
										<RemoveCircleOutlineIcon />
									) : actionCrud === "enable" ? (
										<AddCircleOutlineIcon />
									) : (
										<SaveIcon />
									)
								}
							>
								{actionCrud === "disable"
									? "Deshabilitar"
									: actionCrud === "enable"
										? "Habilitar"
										: "Guardar"}
							</Button>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default OrganismoDialog;
