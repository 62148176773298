import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { getSistemasRoles } from  "../../../services/SistemaRolService";
import { getUsuariosOrganismosAreasFunciones } from  "../../../services/UsuarioOrganismoAreaFuncionService";
import { saveUsuarioSistemaRol } from  "../../../services/UsuarioSistemaRolService";
 


const initialState = {
	descripcion: "",
	observaciones: "",
};

const itemGender = "M";
const nameItemText = "Rol asignado a Usuario de Sistema";
//
// #####################################################################################################################
//
const SistemaRolUsuarioDialog = ({
	sistemas,
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [sistemasRoles, setSistemasRoles] = useState([]);
	const [usuarios, setUsuarios] = useState([]);
	const [valueAutoCompleteSistemas, setValueAutoCompleteSistemas] = useState(
		itemCrud.sistema
	);
	const [inputValueAutoCompleteSistemas, setInputValueAutoCompleteSistemas] =
		useState("");
	const [valueAutoCompleteSistemasRoles, setValueAutoCompleteSistemasRoles] = useState(itemCrud.descripcion);

	const [inputValueAutoCompleteSistemasRoles, setInputValueAutoCompleteSistemasRoles] =
		useState("");

	const [valueAutoCompleteUsuarios, setValueAutoCompleteUsuarios] = useState(itemCrud.apellidoUsuario);
	const [inputValueAutoCompleteUsuarios, setInputValueAutoCompleteUsuarios] =
		useState("");
	//
	// ################################################################################
	//
	useEffect(() => {
		if (actionCrud === "create") {
			setValueAutoCompleteSistemas("");
			setItemCrudLocal(initialState);
		} else {
			setValueAutoCompleteSistemas(itemCrud.sistema);
			setItemCrudLocal(itemCrud);
		}
	}, []);

	//
	// ################################################################################
	//
	const handleCloseDialog = () => {
		onClose();
	};

	const handleChange = (e) => {
		setItemCrudLocal({
			...itemCrudLocal,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = () => {
		if (actionCrud === "create" || actionCrud === "update") {
			if (inputValueAutoCompleteSistemas === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Sistema",
				});
				setShowMessage(true);
				return;
			}
			if (inputValueAutoCompleteSistemasRoles === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Rol",
				});
				setShowMessage(true);
				return;
			}
			if (inputValueAutoCompleteUsuarios === "") {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar un Usuario",
				});
				setShowMessage(true);
				return;
			}
		}
		save();
	};

	const save = async () => {
		let _itemCrud = {
			idSistema: valueAutoCompleteSistemas?.id,
			idSistemaRol: valueAutoCompleteSistemasRoles?.id,
			observaciones: itemCrudLocal?.observaciones,
			// HARDCODED HARDCODED HARDCODED HARDCODED HARDCODED HARDCODED HARDCODED HARDCODED
			idUsuario: valueAutoCompleteUsuarios?.id ? valueAutoCompleteUsuarios?.id : itemCrudLocal.idUsuario,
			// HARDCODED HARDCODED HARDCODED HARDCODED HARDCODED HARDCODED HARDCODED HARDCODED
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};

		if (actionCrud !== "create") {
			_itemCrud.id = itemCrud.id;
		}
		setIsDoingSomething(true);
		const response = await saveUsuarioSistemaRol(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
			onSave();
		}

		setMessage({
			messageText: response.messageText,
			severity: response.messageSeverity,
		});
		setShowMessage(true);
	};
	//
	// ################################################################################
	//

	const loadRoles = async (newValue) => {
		let _params = {
			idSistema: newValue?.id || valueAutoCompleteSistemas?.id,
		};

		setIsDoingSomething(true);
		const response = await getSistemasRoles(_params);
		setIsDoingSomething(false);

		if (response.ok) {
			let _response = response.data.data;
			let _roles = [];
			_response.forEach((item) => {
				_roles.push({
					id: item.id,
					label: item.descripcion_sistema_rol,
				});
			});

			setSistemasRoles(_roles);
		} else {
			setSistemasRoles([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}

	};
	//
	// ################################################################################
	//

	const loadUsuarios = async (newValue) => {
		let _params = {
			idSistema: newValue?.id,
		};

		setIsDoingSomething(true);
		const response = await getUsuariosOrganismosAreasFunciones(_params);
		setIsDoingSomething(false);
		if (response.ok) {
			let _response = response.data.data;
			let _usuarios = [];
			_response.forEach((item) => {
				_usuarios.push({
					id: item.id_usuario,
					label: item.apellido_usuario + ', ' + item.nombre_usuario,
				});
			});

			setUsuarios(_usuarios);
		} else {
			setUsuarios([]);
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};
	//
	// ################################################################################
	//

	return (
		<>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
				        <DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					 
				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
							? "Editando"
							: actionCrud === "enable"
								? "Habilitando"
								: actionCrud === "disable"
									? "Deshabilitando"
									: actionCrud === "read"
										? "Detalle"
										: ""}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					{actionCrud === "view" && (
						<Typography
							sx={{
								mx: 2,
								p: 1,
								backgroundcolor: "Tomato",
								borderRadius: "5px",
								fontSize: "20px",
								textAlign: "center",
							}}
						>
							Sistema deshabilitado
						</Typography>
					)}
					<Box sx={{ m: 1, p: 1 }}>
						<Autocomplete
							id="sistema"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteSistemas}
							onChange={(event, newValue) => {
								setValueAutoCompleteSistemas(newValue);
								loadRoles(newValue)
								loadUsuarios(newValue)
							}}
							inputValue={inputValueAutoCompleteSistemas}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteSistemas(newInputValue);

							}}
							options={sistemas}
							renderInput={(params) => (
								<TextField {...params} label="Sistema" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={
								actionCrud === "disable" ||
								actionCrud === "enable" ||
								actionCrud === "read"
							}
						/>

						<Autocomplete
							id="rol"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteSistemasRoles}
							onChange={(event, newValue) => {
								setValueAutoCompleteSistemasRoles(newValue);

							}}
							inputValue={inputValueAutoCompleteSistemasRoles}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteSistemasRoles(newInputValue);
							}}
							options={sistemasRoles}
							renderInput={(params) => (
								<TextField {...params} label="Rol" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={
								actionCrud === "disable" ||
								actionCrud === "enable" ||
								actionCrud === "read"
							}
						/>
						<Autocomplete
							id="usuarios"
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteUsuarios}
							onChange={(event, newValue) => {
								setValueAutoCompleteUsuarios(newValue);
							}}
							inputValue={inputValueAutoCompleteUsuarios}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteUsuarios(newInputValue);
							}}
							options={usuarios}
							renderInput={(params) => (
								<TextField {...params} label="Usuario" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disabled={
								actionCrud === "disable" ||
								actionCrud === "enable" ||
								actionCrud === "read"
							}
						/>


						<TextField
							variant="outlined"
							size="small"
							name="observaciones"
							label="Observaciones"
							value={itemCrudLocal.observaciones}
							onChange={handleChange}
							disabled={
								actionCrud === "disable" ||
								actionCrud === "enable" ||
								actionCrud === "read"
							}
							sx={{ width: "100%", pb: 2 }}
						/>

						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									mt: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Creado: {itemCrudLocal.fechaCreacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuarioActualiza}{" "}
									{itemCrudLocal.apellidoUsuarioActualiza} ({itemCrudLocal.usernameUsuarioActualiza})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>									
							Cancelar
						</Button>

						{actionCrud !== "read" && (
							<Button
								sx={{ mr: 1, backgroundcolor: "warning" }}
								variant="contained"
								onClick={handleSave}
								startIcon={
									actionCrud === "disable" ? (
										<RemoveCircleOutlineIcon />
									) : actionCrud === "enable" ? (
										<AddCircleOutlineIcon />
									) : (
										<SaveIcon />
									)
								}
							>
								{actionCrud === "disable"
									? "Deshabilitar"
									: actionCrud === "enable"
										? "Habilitar"
										: "Guardar"}
							</Button>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default SistemaRolUsuarioDialog;
