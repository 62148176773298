const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: "full", // full, close, compact, mobile,
    theme: "slateDark1", // View all valid theme colors inside MatxTheme/themeColors.js
    /*bgImgURL: "/assets/images/sidebar/sidebar-bg-dark.jpg"*/
  },
  topbar: {
    show: true,
    fixed: true,
    theme: "slateDark2" // View all valid theme colors inside MatxTheme/themeColors.js
  }
};

export default Layout1Settings;
