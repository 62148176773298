import { format } from "date-fns";
import { isValidDate } from  "../../../utils/utils";
import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
// import DatePicker from "@mui/lab/DatePicker";
import { DatePicker } from "@mui/x-date-pickers";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveUsuarioLicencia } from  "../../../services/UsuarioLicenciaService";
 


const initialState = {
	apellidoUsuarioLicencia: "",
	nombreUsuarioLicencia: "",
	usernameUsuarioLicencia: "",
	fechaDesde: null,
	fechaHasta: null,
	tipoLicencia: "",
	descripcion: "",
	origenDatos: "manual",
};

const origenDatosOptions = [
	{
		id: "manual",
		descripcion: "Manual",
	},
	{
		id: "automatico",
		descripcion: "Automático",
	},
];


const itemGender = "F";
const nameItemText = "Licencia";

//
// #####################################################################################################################
//
const UsuarioLicenciaDialog = ({
	usuarios,
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage,
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);

	const [valueAutoCompleteUsuarios, setValueAutoCompleteUsuarios] = useState({
		id: "",
		label: "",
		nombre: "",
		apellido: "",
		username: "",
	});
	const [inputValueAutoCompleteUsuarios, setInputValueAutoCompleteUsuarios] =
		useState("");

	useEffect(() => {
		if (actionCrud === "create") {
			clean();
			setItemCrudLocal(initialState);
		} else {
			setItemCrudLocal(itemCrud);
			setValueAutoCompleteUsuarios(itemCrud.usernameUsuarioLicencia);

		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleCloseDialog() {
		clean();
		onClose();
	}

	const handleChange = (e) => {
		setItemCrudLocal({
			...itemCrudLocal,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeFechaDesde = (fechaDesde) => {
		setItemCrudLocal({
			...itemCrudLocal,
			fechaDesde: fechaDesde,
		});
	};

	const handleChangeFechaHasta = (fechaHasta) => {
		setItemCrudLocal({
			...itemCrudLocal,
			fechaHasta: fechaHasta,
		});
	};

	const clean = () => {
		itemCrudLocal.apellidoUsuarioLicencia = '';
		itemCrudLocal.nombreUsuarioLicencia = '';
		itemCrudLocal.usernameUsuarioLicencia = '';
	};

	const handleSave = () => {
		if (actionCrud === "create" || actionCrud === "update") {

			if (
				itemCrudLocal.fechaHasta && (itemCrudLocal.fechaHasta < itemCrudLocal.fechaDesde)
			) {
				setMessage({
					severity: "error",
					messageText: "La fecha 'hasta' debe ser mayor o igual que la fecha 'desde'",
				});
				setShowMessage(true);
				return;
			}

			if (
				itemCrudLocal.usernameUsuarioLicencia.trim() === "") {
				setMessage({
					severity: "error",
					messageText:
						"Debe seleccionar un usuario",
				});
				setShowMessage(true);
				return;
			}

			if (!isValidDate(itemCrudLocal.fechaDesde)) {
				setMessage({
					severity: "error",
					messageText: "La fecha 'desde' ingresada no es válida",
				});
				setShowMessage(true);
				return;
			}

			if (itemCrudLocal.fechaHasta && !isValidDate(itemCrudLocal.fechaHasta)) {
				setMessage({
					severity: "error",
					messageText: "La fecha 'hasta' ingresada no es válida",
				});
				setShowMessage(true);
				return;
			}


		}
		save();
	};

	const save = async () => {
		let _itemCrud = {
			idUsuarioLicencia: itemCrudLocal.idUsuarioLicencia,
			nombreUsuarioLicencia: itemCrudLocal.nombre,
			usernameUsuarioLicencia: itemCrudLocal.username,
			fechaDesde: itemCrudLocal.fechaDesde
				? format(new Date(itemCrudLocal.fechaDesde), "yyyy/MM/dd")
				: null,
			fechaHasta: itemCrudLocal.fechaHasta
				? format(new Date(itemCrudLocal.fechaHasta), "yyyy/MM/dd")
				: null,
			tipoLicencia: itemCrudLocal.tipoLicencia,
			descripcion: itemCrudLocal.descripcion,
			origenDatos: itemCrudLocal.origenDatos,
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};
		if (actionCrud !== "create") {
			_itemCrud.id = itemCrudLocal.id;
		}
		setIsDoingSomething(true);
		const response = await saveUsuarioLicencia(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
			onSave();
		}

		setMessage({
			messageText: response.messageText,
			severity: response.messageSeverity,
		});
		setShowMessage(true);
	};

	return (
		<>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
				        <DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					 
				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
							? "Editando"
							: actionCrud === "enable"
								? "Habilitando"
								: actionCrud === "read"
								? "Detalle"
								: "Deshabilitando"}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					<Box sx={{ m: 1, p: 1 }}>
						<Autocomplete
							id="usuario"
							size="small"
							disabled={actionCrud !== 'create'}
							value={valueAutoCompleteUsuarios}
							onChange={(event, newValue) => {
								setValueAutoCompleteUsuarios(newValue);
								itemCrudLocal.apellidoUsuarioLicencia = newValue.apellido;
								itemCrudLocal.nombreUsuarioLicencia = newValue.nombre;
								itemCrudLocal.usernameUsuarioLicencia = newValue.username;
								itemCrudLocal.idUsuarioLicencia = newValue.id;

							}}
							inputValue={inputValueAutoCompleteUsuarios}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteUsuarios(newInputValue);
							}}
							options={usuarios}
							renderInput={(params) => (
								<TextField {...params} label="Usuario" />
							)}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disableClearable={true}
							sx={{ width: "100%", pb: 1 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="apellidoUsuarioLicencia"
							label="Apellido"
							value={itemCrudLocal.apellidoUsuarioLicencia}
							onChange={handleChange}
							disabled={true}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="nombreUsuarioLicencia"
							label="Nombre"
							value={itemCrudLocal.nombreUsuarioLicencia}
							onChange={handleChange}
							disabled={true}
							sx={{ width: "100%", pb: 1 }}
							autoFocus={true}
							inputProps={{ maxLength: 80 }}
						/>

						<Box sx={{ pb: 1 }}>
							<DatePicker
								sx={{
									width: "100%",
									mb: 1,
									'& .MuiInputLabel-root': {
										transform: 'translate(14px, 9px) scale(1)',
									},
									'& .MuiInputBase-root': {
										paddingRight: '10px',
									},
									'& .MuiInputLabel-shrink': {
										transform: 'translate(14px, -8px) scale(0.75)',
									},
								}}
								id="fechaDesde"
								label="Desde"
								variant="outlined"
								name="fechaDesde"
								value={itemCrudLocal.fechaDesde}
								minDate={actionCrud === 'create' ? new Date() : false }
								onChange={(fechaDesde) =>
									handleChangeFechaDesde(fechaDesde)
								}
								renderInput={(params) => <TextField size="small" {...params} />}
								inputFormat="dd/MM/yyyy"
								disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							/>
						</Box>

						<Box sx={{ pb: 1 }}>
							<DatePicker
								sx={{
									width: "100%",
									mb: 1,
									'& .MuiInputLabel-root': {
										transform: 'translate(14px, 9px) scale(1)',
									},
									'& .MuiInputBase-root': {
										paddingRight: '10px',
									},
									'& .MuiInputLabel-shrink': {
										transform: 'translate(14px, -8px) scale(0.75)',
									},
								}}
								id="fechaHasta"
								label="Hasta"
								variant="outlined"
								name="fechaHasta"
								//defaultValue = {("")}
								value={ itemCrudLocal.fechaHasta} 
								onChange={(fechaHasta) =>
									handleChangeFechaHasta(fechaHasta)
								}
								renderInput={(params) => <TextField size="small" {...params} />}
								// inputFormat="dd/MM/yyyy"
								disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							/>
						</Box>

						<TextField
							variant="outlined"
							size="small"
							name="tipoLicencia"
							label="Tipo de licencia"
							value={itemCrudLocal.tipoLicencia}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="descripcion"
							label="Descripción"
							value={itemCrudLocal.descripcion}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						<FormControl
							sx={{ width: "100%", pb: 1 }}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						>
							<InputLabel id="origen-datos-select-label">
								Origen de Datos
							</InputLabel>
							<Select
								sx={{ width: "100%", mr: 1 }}
								labelId="origenDatos"
								label="Origen de Datos"
								id="origenDatos"
								name="origenDatos"
								value={itemCrudLocal.origenDatos}
								size="small"
								onChange={handleChange}
							>
								{origenDatosOptions.map((item) => (
									<MenuItem key={item.id} value={item.id}>
										{item.descripcion}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									mt: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuarioActualiza}{" "}
									{itemCrudLocal.apellidoUsuarioActualiza} ({itemCrudLocal.usernameUsuarioActualiza})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>									
							Cancelar
						</Button>

						{(actionCrud !== "read") && (	
							<Button
							sx={{ mr: 1, backgroundcolor: "warning" }}
							variant="contained"
							onClick={handleSave}
							startIcon={
								actionCrud === "disable" ? (
									<RemoveCircleOutlineIcon />
								) : actionCrud === "enable" ? (
									<AddCircleOutlineIcon />
								) : (
									<SaveIcon />
								)
							}
						>
							{actionCrud === "disable"
								? "Deshabilitar"
								: actionCrud === "enable"
									? "Habilitar"
									: "Guardar"}
							</Button>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default UsuarioLicenciaDialog;
