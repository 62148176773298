import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveAsignation } from  "../../../services/SistemaRolCuService";
 

const initialState = {
  idSistema: "",
  idSistemaRol: "",
  sistemaCUList: "",
};

const itemGender = "M";
const nameItemText = "CU asignados al Rol de Sistema";

//
// #####################################################################################################################
//
const SistemaRolDialog = ({
  sistemas,
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [itemCUsLocal, setItemCUsLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [todosSeleccionados, setTodosSeleccionados] = useState(false);

  const [valueAutoCompleteSistemas, setValueAutoCompleteSistemas] = useState(
    itemCrud.sistema
  );
  const [inputValueAutoCompleteSistemas, setInputValueAutoCompleteSistemas] =
    useState(itemCrud.descripcion_sistema);

  useEffect(() => {
    if (actionCrud === "create") {
      setValueAutoCompleteSistemas(sistemas[0]);
      setItemCrudLocal(initialState);
    } else {
      setValueAutoCompleteSistemas(itemCrud.descripcion_sistema);
      setItemCrudLocal(itemCrud);
      setItemCUsLocal(itemCrud.sistemaCUList);
    }
  }, []);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (e) => {
    let itemsTemp = itemCUsLocal;
    itemsTemp[e.target.value].asignado = e.target.checked;
    setItemCUsLocal([...itemsTemp]);
  };

  const toggleSeleccionTodos = () => {
    let itemsTemp1 = itemCUsLocal;
    for (let i = 0; i < itemCUsLocal.length; i++) {
      itemsTemp1[i].asignado = !todosSeleccionados;
    }
    setItemCUsLocal([...itemsTemp1]);
    setTodosSeleccionados(!todosSeleccionados);
  };

  const handleSave = (e) => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (inputValueAutoCompleteSistemas === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Sistema",
        });
        setShowMessage(true);
        return;
      }
    }
    save();
  };

  const save = async () => {
    let localsistemaCUList = [];

    itemCUsLocal.forEach(function (item) {
      if (item.asignado) {
        localsistemaCUList.push({ id_sistema_cu: item.id_sistema_cu });
      }
    });

    let _itemCrud = {
      idSistema: itemCrud.idSistema,
      idSistemaRol: itemCrud.idSistemaRol,
      sistemaCUList: localsistemaCUList,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
    };

    setIsDoingSomething(true);
    var actionCrud = "update";
    const response = await saveAsignation(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };
  const labels = [
    itemCrud.sistemaCUList.filter((item) => item.habilitado_sistema_cu),
  ];

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
                <DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					 
				>
          {actionCrud === "create"
            ? itemGender === "M"
              ? "Nuevo"
              : "Nueva"
            : actionCrud === "update"
            ? "Editando"
            : actionCrud === "enable"
            ? "Habilitando"
            : actionCrud === "disable"
            ? "Deshabilitando"
            : actionCrud === "read"
            ? "Detalle"
            : ""}{" "}
          {nameItemText}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDoingSomething && <LinearProgress />}
        <DialogContent>
          <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
            Sistema: {itemCrud.descripcion_sistema} {"               "} Rol:{" "}
            {itemCrud.descripcion_rol}
          </DialogContentText>
        </DialogContent>
        <DialogContent dividers>
          <Box sx={{ display: "flex" }}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Responsabilidad asignada</FormLabel>
              <FormGroup sx={{ m: 3 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} >
                    {(actionCrud !== "read") && (<FormControlLabel
                      label="SELECCIONAR TODOS"
                      control={
                        <Checkbox
                        position='rigth'
                          checked={todosSeleccionados}
                          onChange={toggleSeleccionTodos}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                          name="seleccionarTodos"
                        />
                      }
                    />
                    )}
                  </Grid>
                  {labels[0].map((item, index) => (
                    <Grid item xs={12} key={item.id_sistema_cu}>
                      <FormControlLabel
                          disabled={actionCrud === "read"}
                          control={
                          <Checkbox
                            checked={item.asignado}
                            disabled={!item.habilitado_sistema_cu}
                            onChange={handleChange}
                            key={item.id_sistema_cu}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            name="asignado"
                            id={item.id_sistema_cu}
                            value={index}
                            inputProps={{
                              "aria-label": "Checkbox A",
                            }}
                          />
                        }
                        label={item.descripcion_sistema_cu}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </FormControl>
          </Box>

          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>						
             Cancelar 
            </Button>

            {actionCrud !== "read" && (
              <Button
                sx={{ backgroundcolor: "warning" }}
                variant="contained"
                onClick={handleSave}
                startIcon={
                  actionCrud === "disable" ? (
                    <RemoveCircleOutlineIcon />
                  ) : actionCrud === "enable" ? (
                    <AddCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {actionCrud === "disable"
                  ? "Deshabilitar"
                  : actionCrud === "enable"
                  ? "Habilitar"
                  : "Guardar"}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SistemaRolDialog;
