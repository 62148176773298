import { urlBackendUsuarios } from "../core/configs/config";
import "../utils/axios.interceptor";
import HttpService from '../services/HttpService';
HttpService.configure();

const axiosClient = HttpService.getAxiosClient();

const epName = "/fuero-tipo-elevacion-etapa-procesal/";
const nameItemsText = "FueroTipoElevacionesEtapasProcesales";
const nameItemText = "Fuero-Tipo-Elevacion-Etapa-Procesal";
const itemGender = "M";
const elLa = itemGender === "M" ? "el" : "la";
// const unUna = itemGender === "M" ? "un" : "una";
//
// #############################################################################
//
export const getFueroTipoElevacionesEtapasProcesales = async (params) => {
  let _params = {};
  let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

  if (params.idFuero) {
    _params.id_fuero = params.idFuero;
  }

  if (params.idFueroTipoElevacion) {
    _params.id_fuero_tipo_elevacion = params.idFueroTipoElevacion;
  }

  if (params.idEtapaProcesal) {
    _params.id_etapa_procesal = params.idEtapaProcesal;
  }

  if (params.estado) {
    let estado =
      params.estado === "habilitado"
        ? "H"
        : params.estado === "deshabilitado"
        ? "D"
        : "";
    _params.habilitado = estado;
  }

  if (params.rows) {
    _params.rows = params.rows;
  }

  if (params.first) {
    _params.first = params.first;
  }

  if (params.sortField) {
		_params.sortfield = params.sortField;
	}

	if (params.sortOrder) {
		_params.sortorder = params.sortOrder;
	}

  try {
    const response = await axiosClient.get(`${urlBackendUsuarios}${epName}`, {
      params: _params,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (response.status === 200) {
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        return response;
      }
    }

    // si hay algun error controlado

    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
//
// #############################################################################
//
export const getFueroTipoElevacionEtapaProcesal = async (params) => {
  let _errorMessage = `Se produjo un error al obtener ${elLa} ${nameItemText}`;

	try {
		const response = await axiosClient.get(`${urlBackendUsuarios}${epName}`, {
			params: params,
			headers: {
				"Access-Control-Allow-Origin": "*",
			},
		});

    if (response.status === 200) {
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        return response;
      }
    }

    // si hay algun error controlado

    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};
//
// #############################################################################
//
export const saveFueroTipoElevacionEtapaProcesal = async (actionCrud, item) => {
  let _errorMessage = `No fue posible guardar ${elLa} ${nameItemText}`;

  let _item = {
    id_fuero: item.idFuero,
    id_fuero_tipo_elevacion: item.idFueroTipoElevacion,
    id_etapa_procesal: item.idEtapaProcesal,
    orden: item.orden,
    observaciones: item.observaciones,
    username_actualiza: item.usernameUsuarioActualiza,
  };
  if (actionCrud !== "create") {
    _item.id = item.id;
  }

	try {
		let response = {};
		if (actionCrud === "create") {
			response = await axiosClient.post(`${urlBackendUsuarios}${epName}`, _item, {
        headers: { "Access-Control-Allow-Origin": "*" },
      });
		} else if (actionCrud === "update") {
			response = await axiosClient.put(`${urlBackendUsuarios}${epName}`, _item, {
        headers: { "Access-Control-Allow-Origin": "*" },
      });
		} else if (actionCrud === "disable" || actionCrud === "enable") {
		
			response = await axiosClient.delete(`${urlBackendUsuarios}${epName}`, {
        params: {
          id: _item.id,
          username_actualiza: _item.username_actualiza,
          action: actionCrud,
        },
        headers: { "Access-Control-Allow-Origin": "*" },
      });
		}

    if (response.status === 200) {
      console.log(response.data);
      let _error = "error_code" in response.data;
      if (!_error) {
        // si no hay error controlado
        response.ok = true;
        response.messageText =
          `${nameItemText}` +
          (actionCrud === "disable"
            ? itemGender === "M"
              ? " deshabilitado"
              : " deshabilitada"
            : actionCrud === "enable"
            ? itemGender === "M"
              ? " habilitado"
              : " habilitada"
            : itemGender === "M"
            ? " guardado"
            : " guardada");
        response.messageSeverity = "success";
      } else {
        // si hay error controlado
        console.log(_errorMessage);
        console.log(response);

        let _message_code = response.data.message_code;
        if (_message_code === "err-fuero-tipo-elevacion-etapa-procesal-already-exist") {
          response.messageText = `Ya existe ${elLa} ${nameItemText}. Verifique los inhabilitados`;
        } else if (_message_code === "err-fuero-tipo-elevacion-etapa-procesal") {
          response.messageText = `El Fuero Tipo Elevación no corresponde a ese Fuero`;
        } else {
          response.messageText = _errorMessage;
        }
        response.ok = false;
        response.messageSeverity = "error";
      }
      return response;
    }
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.log(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
  }
};

