import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { getFueroTipoElevacionesEtapasProcesales } from "../../../services/FueroTipoElevacionEtapaProcesalService";
import { getFuerosSubtiposActJuzgado } from "../../../services/FueroSubtipoActJuzgadoService";
import { saveFueroTipoElevacionEtapaProcesalSubtipoActJuzgado } from "../../../services/FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoService";


const initialState = {
  observaciones: "",
  avanzaEtapa: false,
};


const itemGender = "M";
const nameItemText = "Fuero Tipo Elevación Etapa Procesal - Subtipo Actuación Juzgado";

const FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoDialog = ({
  fueros,
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [fueroTipoElevacionesEtapasProcesalesLocal, setFueroTipoElevacionesEtapasProcesalesLocal] = useState([]);
  const [subtiposActJuzgadoLocal, setSubtiposActJuzgadoLocal] = useState([]);
  const [valueAutoCompleteFueros, setValueAutoCompleteFueros] = useState(itemCrud.fuero);
  const [inputValueAutoCompleteFueros, setInputValueAutoCompleteFueros] = useState("");
  const [valueAutoCompleteFueroTipoElevacionesEtapasProcesales, setValueAutoCompleteFueroTipoElevacionesEtapasProcesales] = useState(itemCrud.fueroTipoElevacionEtapaProcesal);
  const [inputValueAutoCompleteFueroTipoElevacionesEtapasProcesales, setInputValueAutoCompleteFueroTipoElevacionesEtapasProcesales] = useState("");
  const [valueAutoCompleteSubtiposActJuzgado, setValueAutoCompleteSubtiposActJuzgado] = useState(itemCrud.subtipoActJuzgado);
  const [inputValueAutoCompleteSubtiposActJuzgado, setInputValueAutoCompleteSubtiposActJuzgado] = useState("");

  useEffect(() => {
    if (actionCrud === "create") {
      setValueAutoCompleteFueros("");
      setItemCrudLocal(initialState);
    } else {
      setValueAutoCompleteFueros(itemCrud.fuero);
      setValueAutoCompleteFueroTipoElevacionesEtapasProcesales(itemCrud.fueroTipoElevacionEtapaProcesal);
      setValueAutoCompleteSubtiposActJuzgado(itemCrud.subtipoActJuzgado);
      setItemCrudLocal(itemCrud);
      loadFueroTipoElevacionesEtapasProcesales(itemCrud.fuero);
      loadSubtiposActJuzgado(itemCrud.fuero);
    }
  }, []);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (e) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      [e.target.name]: e.target.value,
    });
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFueros(newValue);
    setValueAutoCompleteFueroTipoElevacionesEtapasProcesales("");
    setValueAutoCompleteSubtiposActJuzgado("");
    loadFueroTipoElevacionesEtapasProcesales(newValue);
    loadSubtiposActJuzgado(newValue);

  };

  const handleSave = () => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (inputValueAutoCompleteFueros === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Fuero",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteFueroTipoElevacionesEtapasProcesales === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Fuero Tipo Elevación: Etapa Procesal",
        });
        setShowMessage(true);
        return;
      }

      if (inputValueAutoCompleteSubtiposActJuzgado === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar un Subtipo Actuación Juzgado",
        });
        setShowMessage(true);
        return;
      }

      if (itemCrudLocal.avanzaEtapa === "" || itemCrudLocal.avanzaEtapa === null || itemCrudLocal.avanzaEtapa === undefined) {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar si avanza etapa",
        });
        setShowMessage(true);
        return;
      }
    }
    save();
  };

  const save = async () => {
    let _itemCrud = {
      idFuero: valueAutoCompleteFueros?.id,
      idFueroTipoElevacionEtapaProcesal: valueAutoCompleteFueroTipoElevacionesEtapasProcesales?.id,
      idSubtipoActJuzgado: valueAutoCompleteSubtiposActJuzgado?.id,
      avanzaEtapa: itemCrudLocal.avanzaEtapa,
      observaciones: itemCrudLocal?.observaciones,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
    };
    if (actionCrud !== "create") {
      _itemCrud.id = itemCrud.id;
    }
    setIsDoingSomething(true);
    const response = await saveFueroTipoElevacionEtapaProcesalSubtipoActJuzgado(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };


  const loadFueroTipoElevacionesEtapasProcesales = async (newValue) => {
    let _params = {
      idFuero: newValue?.id || valueAutoCompleteFueros?.id,
      idFueroTipoElevacionEtapaProcesal: newValue?.id,
    };
    setIsDoingSomething(true);
    const response = await getFueroTipoElevacionesEtapasProcesales(_params);
    setIsDoingSomething(false);

    if (response.ok) {
      let _response = response.data.data;
      let _FueroTipoElevacionesEtapasProcesales = [];

      _response.forEach((item) => {
        _FueroTipoElevacionesEtapasProcesales.push({
          id: item.id,
          label: item.descripcion_tipo_elevacion + ': ' + item.descripcion_etapa_procesal,
        });
      });

      setFueroTipoElevacionesEtapasProcesalesLocal(_FueroTipoElevacionesEtapasProcesales);
    } else {
      setFueroTipoElevacionesEtapasProcesalesLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };


  const loadSubtiposActJuzgado = async (newValue) => {
    let _params = {
      idFuero: newValue?.id || valueAutoCompleteFueros?.id,
    };
    setIsDoingSomething(true);
    const response = await getFuerosSubtiposActJuzgado(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _subtipos_act_juzgado = [];
      _response.forEach((item) => {
        _subtipos_act_juzgado.push({
          id: item.id_subtipo_act_juzgado,
          label: item.descripcion_tipo_act_juzgado + ': ' + item.descripcion_subtipo_act_juzgado,
        });
      });
      setSubtiposActJuzgadoLocal(_subtipos_act_juzgado);
    } else {
      setSubtiposActJuzgadoLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"
          textAlign={{ xs: "center", sm: "center" }}

        >
          {actionCrud === "create"
            ? itemGender === "M"
              ? "Nuevo"
              : "Nueva"
            : actionCrud === "update"
              ? "Editando"
              : actionCrud === "enable"
                ? "Habilitando"
                : actionCrud === "disable"
                  ? "Deshabilitando"
                  : actionCrud === "read"
                    ? "Detalle"
                    : ""}{" "}
          {nameItemText}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDoingSomething && <LinearProgress />}

        <DialogContent dividers>
          <Box sx={{ m: 1, p: 1 }}>
            <Autocomplete
              id="fuero"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteFueros}
              onChange={handleFueroChange}
              inputValue={inputValueAutoCompleteFueros}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteFueros(newInputValue);
              }}
              options={fueros}
              renderInput={(params) => (
                <TextField {...params} label="Fuero" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              disableClearable={true}
            />

            <Autocomplete
              id="fueroTipoElevacionEtapaProcesal"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteFueroTipoElevacionesEtapasProcesales}
              onChange={(event, newValue) => {
                setValueAutoCompleteFueroTipoElevacionesEtapasProcesales(newValue);
              }
              }
              inputValue={inputValueAutoCompleteFueroTipoElevacionesEtapasProcesales}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteFueroTipoElevacionesEtapasProcesales(newInputValue);
              }}
              options={fueroTipoElevacionesEtapasProcesalesLocal}
              renderInput={(params) => (
                <TextField {...params} label="Tipo Elevación: Etapa Procesal" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read" || !valueAutoCompleteFueros?.id}
            />


            <Autocomplete
              id="subtipoActJuzgado"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteSubtiposActJuzgado}
              onChange={(event, newValue) => {
                setValueAutoCompleteSubtiposActJuzgado(newValue);
              }}
              inputValue={inputValueAutoCompleteSubtiposActJuzgado}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteSubtiposActJuzgado(newInputValue);
              }}
              options={subtiposActJuzgadoLocal}
              renderInput={(params) => (
                <TextField {...params} label="Tipo: Subtipo Actuación Juzgado" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
            />

            <FormControl variant="outlined" size="small" sx={{ width: "100%", pb: 1 }}>
              <InputLabel id="avanzaEtapa">Avanza Etapa</InputLabel>
              <Select
                labelId="avanzaEtapa"
                name="avanzaEtapa"
                value={itemCrudLocal.avanzaEtapa ?? false}
                onChange={handleChange}
                label="Avanza Etapa"
                disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              >
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              size="small"
              name="observaciones"
              label="Observaciones"
              value={itemCrudLocal.observaciones}
              onChange={handleChange}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              sx={{ width: "100%", pb: 1 }}

            />

            {actionCrud !== "create" && (
              <Box
                sx={{
                  p: 1,
                  mt: 1,
                  color: "SlateGrey",
                  border: "0px solid DarkGray",
                  borderRadius: "3px",
                }}
              >
                <Typography variant="h8" component="div" gutterBottom>
                  Última actualización: {itemCrudLocal.fechaActualizacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
                  {itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={handleCloseDialog}
              startIcon={<CloseIcon />}
            >
              Cancelar
            </Button>

            {(actionCrud !== "read") && (
              <Button
                sx={{ mr: 1, backgroundcolor: "warning" }}
                variant="contained"
                onClick={handleSave}
                startIcon={
                  actionCrud === "disable" ? (
                    <RemoveCircleOutlineIcon />
                  ) : actionCrud === "enable" ? (
                    <AddCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {actionCrud === "disable"
                  ? "Deshabilitar"
                  : actionCrud === "enable"
                    ? "Habilitar"
                    : "Guardar"}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoDialog;
