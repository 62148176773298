import { format } from "date-fns";
import { isValidDate } from  "../../../utils/utils";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveInhabilidad } from  "../../../services/InhabilidadService";
 
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getOrganismos } from  "../../../services/OrganismoService";

const initialState = {
  descripcion: "",
  observaciones: "",
  tipo: "",
  fechaDesde: null,
  fechaHasta: null,
	juezUsername: "",
	juezApellido: "",
	juezNombre: "",
  organismo: "",
  fuero: "",
  circunscripcionJudicialOrganismo: "",
};

const tipoOptions = [
  {
    id: "GENERAL",
    descripcion: "General",
  },
  {
    id: "ORGANISMO",
    descripcion: "Organismo",
  },
  {
    id: "JUEZ",
    descripcion: "Juez",
  },
];

const circunscripcionJudicialOptions = [
  {
    id: "1",
    descripcion: "Primera",
  },
  {
    id: "2",
    descripcion: "Segunda",
  },
  {
    id: "3",
    descripcion: "Tercera",
  },
  {
    id: "4",
    descripcion: "Cuarta",
  },
];

const itemGender = "F";
const nameItemText = "Inhabilidad";

//
// #####################################################################################################################
//
const InhabilidadDialog = ({
  fueros,
  usuarios,
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [valueAutoCompleteUsuarios, setValueAutoCompleteUsuarios] = useState({
    id: "",
    label: "",
  });

  const [inputValueAutoCompleteUsuarios, setInputValueAutoCompleteUsuarios] =
    useState("");
  const [valueAutoCompleteFueros, setValueAutoCompleteFueros] = useState({
    id: "",
    label: "",
  });
  const [inputValueAutoCompleteFueros, setInputValueAutoCompleteFueros] =
    useState("");
  const [valueAutoCompleteOrganismos, setValueAutoCompleteOrganismos] =
    useState({
      id: "",
      label: "",
    });
  const [
    inputValueAutoCompleteOrganismos,
    setInputValueAutoCompleteOrganismos,
  ] = useState("");
  const [organismosLocal, setOrganismosLocal] = useState([]);

  useEffect(() => {
    if (actionCrud === "create") {
      clean();
      setItemCrudLocal(initialState);
    } else {
      setItemCrudLocal(itemCrud);
      setValueAutoCompleteUsuarios(itemCrud.juezUsername);
      setValueAutoCompleteFueros(itemCrud.fuero);
      setValueAutoCompleteOrganismos(itemCrud.organismo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadOrganismos = async (
    fuero,
    circunscripcionJudicial
  ) => {
    let _params = {
      idFuero: fuero?.id || valueAutoCompleteFueros?.id,
      circunscripcionJudicial:
        circunscripcionJudicial ||
        itemCrudLocal.circunscripcionJudicialOrganismo,
    };
    setIsDoingSomething(true);

    const response = await getOrganismos(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _organismos = [];
      _response.forEach((item) => {
        _organismos.push({
          id: item.id,
          label: item.descripcion,
        });
      });
      setOrganismosLocal(_organismos);
    } else {
      setOrganismosLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  function handleCloseDialog() {
    clean();
    onClose();
  }

  const handleChange = (e) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeFechaDesde = (fechaDesde) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      fechaDesde: fechaDesde,
    });
  };

  const handleChangeFechaHasta = (fechaHasta) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      fechaHasta: fechaHasta,
    });
  };

  const handleCircunscripcionChange = (event) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      circunscripcionJudicialOrganismo: event.target.value,
    });
    setValueAutoCompleteOrganismos("");
    loadOrganismos(valueAutoCompleteFueros, event.target.value);
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFueros(newValue);
    setValueAutoCompleteOrganismos("");
    loadOrganismos(newValue, itemCrudLocal.circunscripcionJudicialOrganismo);
  };

  const clean = () => {
    itemCrudLocal.usuario = "";
    itemCrudLocal.fuero = "";
    itemCrudLocal.organismo = "";
  };

  const handleSave = () => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (
        itemCrudLocal.fechaHasta &&
        itemCrudLocal.fechaHasta < itemCrudLocal.fechaDesde
      ) {
        setMessage({
          severity: "error",
          messageText:
            "La fecha 'hasta' debe ser mayor o igual que la fecha 'desde'",
        });
        setShowMessage(true);
        return;
      }

      if (itemCrudLocal.tipo === "" || !itemCrudLocal.tipo) {
        setMessage({
          severity: "error",
          messageText: "Debe seleccionar un tipo",
        });
        setShowMessage(true);
        return;
      }
      
      if (itemCrudLocal.tipo === "JUEZ" && itemCrudLocal.juezUsername.trim() === "") {
        setMessage({
          severity: "error",
          messageText: "Debe seleccionar un usuario",
        });
        setShowMessage(true);
        return;
      }

			if (itemCrudLocal.tipo === "ORGANISMO" && !valueAutoCompleteOrganismos?.id) {
				setMessage({
					severity: "error",
					messageText: "Debe seleccionar un organismo",
				});
				setShowMessage(true);
				return;
			}

      if (itemCrudLocal.descripcion.trim() === "") {
        setMessage({
          severity: "error",
          messageText: "Debe ingresar una descripción",
        });
        setShowMessage(true);
        return;
      }

			
      if (!isValidDate(itemCrudLocal.fechaDesde) || !itemCrudLocal.fechaDesde) {
        setMessage({
          severity: "error",
          messageText: "La fecha 'desde' ingresada no es válida",
        });
        setShowMessage(true);
        return;
      }

      if (itemCrudLocal.fechaHasta && !isValidDate(itemCrudLocal.fechaHasta)) {
        setMessage({
          severity: "error",
          messageText: "La fecha 'hasta' ingresada no es válida",
        });
        setShowMessage(true);
        return;
      }
    }
    save();
  };

  const save = async () => {
    let _itemCrud = {
			idJuez: itemCrudLocal.juezId,
			idOrganismo: valueAutoCompleteOrganismos?.id,
			fechaDesde: itemCrudLocal.fechaDesde
        ? format(new Date(itemCrudLocal.fechaDesde), "yyyy/MM/dd")
        : null,
      fechaHasta: itemCrudLocal.fechaHasta
        ? format(new Date(itemCrudLocal.fechaHasta), "yyyy/MM/dd")
        : null,
      tipo: itemCrudLocal.tipo,
      descripcion: itemCrudLocal.descripcion,
      observaciones: itemCrudLocal.observaciones,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
    };
    if (actionCrud !== "create") {
      _itemCrud.id = itemCrudLocal.id;
    }
    setIsDoingSomething(true);
    const response = await saveInhabilidad(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Dialog
          open={true}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth={true}
          scroll="paper"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            textAlign={{ xs: "center", sm: "center" }}
          >
            {actionCrud === "create"
              ? itemGender === "M"
                ? "Nuevo"
                : "Nueva"
              : actionCrud === "update"
              ? "Editando"
              : actionCrud === "enable"
              ? "Habilitando"
              : actionCrud === "read"
              ? "Detalle"
              : "Deshabilitando"}{" "}
            {nameItemText}
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          {isDoingSomething && <LinearProgress />}

          <DialogContent dividers>
            <Box sx={{ m: 1, p: 1 }}>
              <Box sx={{ pb: 1 }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="tipo-select-label">Tipo</InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="tipo"
                    label="Tipo"
                    id="tipo"
                    name="tipo"
                    value={itemCrudLocal?.tipo || ""}
                    size="small"
                    onChange={handleChange}
                    disabled={
                      actionCrud === "disable" ||
                      actionCrud === "enable" ||
                      actionCrud === "read"
                    }
                  >
                    {tipoOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {itemCrudLocal?.tipo === "ORGANISMO" && (
                <Autocomplete
                  id="fuero"
                  sx={{ width: "100%", pb: 1 }}
                  size="small"
                  value={valueAutoCompleteFueros}
                  onChange={handleFueroChange}
                  inputValue={inputValueAutoCompleteFueros}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoCompleteFueros(newInputValue);
                  }}
                  options={fueros}
                  renderInput={(params) => (
                    <TextField {...params} label="Fuero" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  disabled={
                    actionCrud === "disable" ||
                    actionCrud === "enable" ||
                    actionCrud === "read"
                  }
                  disableClearable={true}
                />
              )}
              {itemCrudLocal?.tipo === "ORGANISMO" && (
                <FormControl
                  sx={{ width: "100%", pb: 1 }}
                  disabled={
                    actionCrud === "disable" ||
                    actionCrud === "enable" ||
                    actionCrud === "read"
                  }
                >
                  <InputLabel id="circunscripcionJudicial-select-label">
                    Circunscripción Judicial
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="circunscripcionJudicial"
                    label="Circunscripción Judicial"
                    id="circunscripcionJudicialOrganismo"
                    name="circunscripcionJudicial"
                    value={itemCrudLocal.circunscripcionJudicialOrganismo}
                    size="small"
                    onChange={handleCircunscripcionChange}
                  >
                    {circunscripcionJudicialOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {itemCrudLocal?.tipo === "ORGANISMO" && (
                <Autocomplete
                  id="organismo"
                  sx={{ width: "100%", pb: 1 }}
                  size="small"
                  value={valueAutoCompleteOrganismos}
                  onChange={(event, newValue) => {
                    setValueAutoCompleteOrganismos(newValue);
                  }}
                  inputValue={inputValueAutoCompleteOrganismos}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoCompleteOrganismos(newInputValue);
                  }}
                  options={organismosLocal}
                  renderInput={(params) => (
                    <TextField {...params} label="Organismo" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  disabled={
                    actionCrud === "disable" ||
                    actionCrud === "enable" ||
                    actionCrud === "read" ||
                    !valueAutoCompleteFueros?.id ||
                    !itemCrudLocal.circunscripcionJudicialOrganismo
                  }
                />
              )}

              {itemCrudLocal?.tipo === "JUEZ" && (
                <Autocomplete
                  id="usuario"
                  size="small"
                  disabled={actionCrud !== "create"}
                  value={valueAutoCompleteUsuarios}
                  onChange={(event, newValue) => {
                    setValueAutoCompleteUsuarios(newValue);
                    itemCrudLocal.juezApellido = newValue.apellido;
                    itemCrudLocal.juezNombre = newValue.nombre;
                    itemCrudLocal.juezUsername = newValue.username;
                    itemCrudLocal.juezId = newValue.id;
                  }}
                  inputValue={inputValueAutoCompleteUsuarios}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoCompleteUsuarios(newInputValue);
                  }}
                  options={usuarios}
                  renderInput={(params) => (
                    <TextField {...params} label="Usuario" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  disableClearable={true}
                  sx={{ width: "100%", pb: 1 }}
                />
              )}

              {itemCrudLocal?.tipo === "JUEZ" && (
                <TextField
                  variant="outlined"
                  size="small"
                  name="apellidoJuez"
                  label="Apellido"
                  value={itemCrudLocal.juezApellido}
                  onChange={handleChange}
                  disabled={true}
                  sx={{ width: "100%", pb: 1 }}
                  inputProps={{ maxLength: 80 }}
                />
              )}

              {itemCrudLocal?.tipo === "JUEZ" && (
                <TextField
                  variant="outlined"
                  size="small"
                  name="nombreJuez"
                  label="Nombre"
                  value={itemCrudLocal.juezNombre}
                  onChange={handleChange}
                  disabled={true}
                  sx={{ width: "100%", pb: 1 }}
                  autoFocus={true}
                  inputProps={{ maxLength: 80 }}
                />
              )}
              <TextField
                variant="outlined"
                size="small"
                name="descripcion"
                label="Descripción"
                value={itemCrudLocal.descripcion}
                onChange={handleChange}
                disabled={
                  actionCrud === "disable" ||
                  actionCrud === "enable" ||
                  actionCrud === "read"
                }
                sx={{ width: "100%", pb: 1 }}
                inputProps={{ maxLength: 80 }}
              />
              <Box sx={{ pb: 1 }}>
                <DatePicker
                  sx={{
                    width: "100%",
                    mb: 1,
                    '& .MuiInputLabel-root': {
                      transform: 'translate(14px, 9px) scale(1)',
                    },
                    '& .MuiInputBase-root': {
                      paddingRight: '10px',
                    },
                    '& .MuiInputLabel-shrink': {
                      transform: 'translate(14px, -8px) scale(0.75)',
                    },
                  }}
                  id="fechaDesde"
                  label="Desde"
                  variant="outlined"
                  name="fechaDesde"
                  value={itemCrudLocal.fechaDesde}
                  onChange={(fechaDesde) => handleChangeFechaDesde(fechaDesde)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                  format="dd/MM/yyyy"
                  disabled={
                    actionCrud === "disable" ||
                    actionCrud === "enable" ||
                    actionCrud === "read"
                  }
                />
              </Box>

              <Box sx={{ pb: 1 }}>
                <DatePicker
                  sx={{
                    width: "100%",
                    mb: 1,
                    '& .MuiInputLabel-root': {
                      transform: 'translate(14px, 9px) scale(1)',
                    },
                    '& .MuiInputBase-root': {
                      paddingRight: '10px',
                    },
                    '& .MuiInputLabel-shrink': {
                      transform: 'translate(14px, -8px) scale(0.75)',
                    },
                  }}
                  id="fechaHasta"
                  label="Hasta"
                  variant="outlined"
                  name="fechaHasta"
                  //defaultValue={""}
                  value={itemCrudLocal.fechaHasta}
                  minDate={itemCrudLocal.fechaDesde}
                  onChange={(fechaHasta) => handleChangeFechaHasta(fechaHasta)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                  format="dd/MM/yyyy"
                  disabled={
                    actionCrud === "disable" ||
                    actionCrud === "enable" ||
                    actionCrud === "read"
                  }
                />
              </Box>

              <TextField
                variant="outlined"
                size="small"
                name="observaciones"
                label="Observaciones"
                value={itemCrudLocal.observaciones}
                onChange={handleChange}
                disabled={
                  actionCrud === "disable" ||
                  actionCrud === "enable" ||
                  actionCrud === "read"
                }
                sx={{ width: "100%", pb: 1 }}
                inputProps={{ maxLength: 80 }}
              />

              {actionCrud !== "create" && (
                <Box
                  sx={{
                    p: 1,
                    mt: 1,
                    color: "SlateGrey",
                    border: "0px solid DarkGray",
                    borderRadius: "3px",
                  }}
                >
                  <Typography variant="h8" component="div" gutterBottom>
                    Última actualización: {itemCrudLocal.fechaActualizacion}
                  </Typography>

                  <Typography variant="h8" component="div" gutterBottom>
                    Último usuario que actualizó:{" "}
                    {itemCrudLocal.nombreUsuarioActualiza}{" "}
                    {itemCrudLocal.apellidoUsuarioActualiza} (
                    {itemCrudLocal.usernameUsuarioActualiza})
                  </Typography>
                </Box>
              )}
            </Box>

            <Box sx={{ textAlign: "right" }}>
              <Button
                sx={{ mr: 1 }}
                variant="outlined"
                onClick={handleCloseDialog}
                startIcon={<CloseIcon />}
              >
                Cancelar
              </Button>

              {actionCrud !== "read" && (
                <Button
                  sx={{ mr: 1, backgroundcolor: "warning" }}
                  variant="contained"
                  onClick={handleSave}
                  startIcon={
                    actionCrud === "disable" ? (
                      <RemoveCircleOutlineIcon />
                    ) : actionCrud === "enable" ? (
                      <AddCircleOutlineIcon />
                    ) : (
                      <SaveIcon />
                    )
                  }
                >
                  {actionCrud === "disable"
                    ? "Deshabilitar"
                    : actionCrud === "enable"
                    ? "Habilitar"
                    : "Guardar"}
                </Button>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </LocalizationProvider>
    </>
  );
};

export default InhabilidadDialog;
